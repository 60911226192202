import React, { Component } from "react";
import { connectComponent } from "../actions/ActionUtils";
import { HttpService } from "../http-service/HttpService";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { injectIntl } from "react-intl";
import { translateValue } from "../i18n/ClebreTranslator";
class UserRolesEdit extends Component {
  constructor(props) {
    super(props);
    const isLoggedUser = this.props.selectedUser.email === this.props.user.email;
    const userGroups = this.props.selectedUser.groups.map((group) => group.name);

    this.state = {
      user: this.props.selectedUser,
      userGroups: userGroups,
      isDisabled: isLoggedUser || !this.props.hasPermission,
    };
  }

  handleChange = (event, newValue) => {
    this.props.setLoading(true);
    const selectedGroups = newValue;
    const updateUserRoleRequest = {
      userId: this.state.user.id,
      body: selectedGroups,
    };
    HttpService.updateRole(updateUserRoleRequest)
      .then((response) => {
        const userGroups = response.groups.map((group) => group.name);
        this.setState({ userGroups: userGroups });
        this.props.setLoading(false);
      })
      .catch(() => {
        this.props.setLoading(false);
      });
  };

  groups = ["ADMIN", "BASIC", "RESEARCHER", "CLASSIFIER", "DOCTOR", "READ_ONLY"];

  render() {
    return (
      <FormControl disabled={this.state.isDisabled}>
        <Autocomplete
          size="small"
          multiple
          options={this.groups}
          noOptionsText={translateValue(this.props.intl, "noOptions")}
          getOptionLabel={(option) => option}
          value={this.state.userGroups}
          ChipProps={
            this.state.userGroups.length === 1 && {
              onDelete: null,
              disabled: true,
            }
          }
          disableClearable
          filterSelectedOptions
          classes={{ root: this.props.classes.autocomplete }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          onChange={this.handleChange}
          disabled={this.state.isDisabled}
        />
      </FormControl>
    );
  }
}

const styles = (theme) => ({
  autocomplete: {
    minWidth: "15vw",
  },
});

export default withStyles(styles)(injectIntl(connectComponent(UserRolesEdit)));
