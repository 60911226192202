import React, { Component } from "react";
import { crc32 } from "crc";
import { connectComponent } from "../../../actions/ActionUtils";
import { HttpService } from "../../../http-service/HttpService";
import CloudUpload from "@material-ui/icons/CloudUpload";
import ReplayIcon from "@material-ui/icons/Replay";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { translateValue } from "../../../i18n/ClebreTranslator";
import { injectIntl } from "react-intl";
import { maxBlockSize } from "./constants";
class AddExaminationFile extends Component {
  handleUpload = async (event) => {
    this.props.setUploadProgressPercentage(0);
    const file = event.target.files[0];

    const fileData = await this.getFileData(file);

    if (!fileData) return;

    if (this.props.type === "resumeUpload") {
      this.resumeFileUpload(this.props.examinationId, this.props.fileId, file, fileData);
    } else {
      this.uploadExaminationFile(this.props.examinationId, file, fileData);
    }
  };

  getFileData = async (file) => {
    if (file.size === 0) {
      this.props.setUploadStatus({ status: "error", message: "emptyFile" });
      this.props.setUploadProgressPercentage(null);
      return null;
    } else {
      const fileData = {};
      fileData["crc"] = await this.generateCheckSum(file);
      fileData["fileSize"] = file.size;
      fileData["fileType"] = this.props.fileToUpload.value;
      fileData["numberOfBlocks"] = Math.ceil(file.size / maxBlockSize);

      return fileData;
    }
  };

  generateCheckSum = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => {
        const data = reader.result;
        const crc = crc32(data);
        resolve(crc);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  finishUpload = (status, message) => {
    this.props.onUploadFinished();
    this.props.setUploadProgressPercentage(null);
    this.props.setUploadStatus({ status: status, message: message });
  };

  resumeFileUpload = (examinationId, fileId, file, fileData) => {
    this.props.setUploadStatus(null);
    HttpService.getFileBlocks(examinationId, fileId)
      .then((response) => {
        if (response.crc === fileData.crc) {
          return this.uploadMissingBlocks(examinationId, fileId, file, fileData, response.blocks);
        } else {
          throw new Error();
        }
      })
      .then(() => {
        return HttpService.commitFileUpload(examinationId, fileId);
      })
      .then(() => {
        this.finishUpload("success", "uploadCompleted");
      })
      .catch(() => {
        this.props.setUploadProgressPercentage(null);
        this.props.setUploadStatus({ status: "error", message: "resumeError" });
      });
  };

  uploadMissingBlocks = async (examinationId, fileId, file, fileData, blocks) => {
    let uploadedBlockNumbers = [];

    blocks.forEach((block) => {
      if (block.uploaded) {
        uploadedBlockNumbers.push(block.number);
      }
    });

    const allBlocks = fileData.numberOfBlocks;
    const progressByBlock = 100 / allBlocks;
    this.props.setUploadProgressPercentage(progressByBlock * uploadedBlockNumbers.length);

    for (let blockNumber = 1; blockNumber <= allBlocks; blockNumber++) {
      if (uploadedBlockNumbers.includes(blockNumber)) {
        // already uploaded - skipping
        continue;
      }

      const startByte = maxBlockSize * (blockNumber - 1);
      let block;
      if (startByte + maxBlockSize > file.size) {
        block = file.slice(startByte, file.size);
      } else {
        block = file.slice(startByte, startByte + maxBlockSize);
      }

      await HttpService.uploadFileBlock(
        examinationId,
        fileId,
        blockNumber,
        block,
        fileData.fileType
      );

      this.incrementProgress(progressByBlock);
    }
  };

  uploadExaminationFile = (examinationId, file, fileData) => {
    this.props.setUploadStatus(null);
    HttpService.initiateFileUpload(examinationId, fileData)
      .then((response) => {
        const examinationFileId = response.id;
        return this.uploadFileInBlocks(examinationId, examinationFileId, file, fileData).then(
          () => {
            return examinationFileId;
          }
        );
      })
      .then((examinationFileId) => {
        return HttpService.commitFileUpload(examinationId, examinationFileId);
      })
      .then(() => {
        this.finishUpload("success", "uploadCompleted");
      })
      .catch(() => {
        this.finishUpload("error", "uploadError");
      });
  };

  uploadFileInBlocks = async (examinationId, examinationFileId, file, fileData) => {
    const numberOfBlocks = fileData.numberOfBlocks;
    const progressByBlock = 100 / numberOfBlocks;
    let startByte = 0;
    this.props.setUploadProgressPercentage(1);

    for (let blockNumber = 1; blockNumber <= numberOfBlocks; blockNumber++) {
      let block;
      if (startByte + maxBlockSize > file.size) {
        block = file.slice(startByte, file.size);
      } else {
        block = file.slice(startByte, startByte + maxBlockSize);
      }

      await HttpService.uploadFileBlock(
        examinationId,
        examinationFileId,
        blockNumber,
        block,
        fileData.fileType
      ).then(() => {
        return examinationFileId;
      });
      startByte = startByte + maxBlockSize;

      this.incrementProgress(progressByBlock);
    }
  };

  incrementProgress = (progressByBlock) => {
    this.props.setUploadProgressPercentage(this.props.uploadProgressPercentage + progressByBlock);
    if (this.props.uploadProgressPercentage > 100) {
      this.props.setUploadProgressPercentage(100);
    }
  };

  render() {
    const fileObject = this.props.fileToUpload;
    return (
      <IconButton variant="contained" color="primary" component="label">
        {this.props.type === "newUpload" ? (
          <Tooltip title={translateValue(this.props.intl, "file.upload")}>
            <CloudUpload />
          </Tooltip>
        ) : (
          <Tooltip title={translateValue(this.props.intl, "file.resumeUpload")}>
            <ReplayIcon />
          </Tooltip>
        )}
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(event) => this.handleUpload(event)}
          accept={fileObject.acceptExtension}
        />
      </IconButton>
    );
  }
}

export default injectIntl(connectComponent(AddExaminationFile));
