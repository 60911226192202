import React, { Component } from "react";
import { HttpService } from "../http-service/HttpService";
import { connectComponent } from "../actions/ActionUtils";
import ClebreFormModal from "../component/clebre-form/ClebreFormModal";
import { FormInputBase } from "../component/input/FormInputBase";
import { translateValue } from "../i18n/ClebreTranslator";
import { injectIntl } from "react-intl";

class EditFirmware extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changelog: props.firmwareToEdit.changelog,
      version: props.firmwareToEdit.firmwareVersion,
      hardwareRevision: props.firmwareToEdit.hardwareRevision,
      id: props.firmwareToEdit.id,
    };
  }

  editFirmware = (event) => {
    HttpService.updateFirmwareMetadata(
      {
        changelog: this.state.changelog,
      },
      this.state.id
    ).then((data) => {
      this.props.actions.closeEditFirmwareModal();
      this.props.loadFirmware();
    });
  };

  editFirmwareForm() {
    return (
      <div className={"editFirmwareForm"}>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "firmware.hardwareRevision")}
            defaultValue={this.state.hardwareRevision}
            disabled={true}
          />
        </div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "firmware.version")}
            defaultValue={this.state.version}
            disabled={true}
          />
        </div>
        <div>
          <FormInputBase
            label="Changelog"
            multiline
            rows={4}
            value={this.state.changelog}
            onChange={(event) => {
              this.setState({ changelog: event.target.value });
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <ClebreFormModal
        i18nLabelTitle="modal.headingEditFirmware"
        modalClass="firmwareEdit"
        formBody={this.editFirmwareForm()}
        onSave={(event) => this.editFirmware(event)}
        show={!!this.props.firmwareToEdit}
        onHide={() => this.props.actions.closeEditFirmwareModal()}
      />
    );
  }
}

export default connectComponent(injectIntl(EditFirmware));
