import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const LinearProgressWithLabel = ({ labelColor, ...props }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" style={{ color: labelColor }}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const ProgressBar = ({ progress, labelColor }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel
        classes={{ root: classes.bar }}
        value={progress}
        labelColor={labelColor}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    zIndex: "20",
    position: "relative",
    marginTop: theme.spacing(1),
  },
  bar: {
    height: "20px",
    borderRadius: "8px",
  },
  // label: {
  //   // color: theme.palette.grey[50],
  //   color: labelColor,

  // },
}));

export default ProgressBar;
