import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { appReducer } from "../reducers/AppReducer";

function configureStore() {
  const reduxStore = createStore(appReducer, composeWithDevTools(applyMiddleware(thunk)));

  if (module.hot) {
    module.hot.accept("../reducers/AppReducer", () => reduxStore.replaceReducer(appReducer));
  }

  return reduxStore;
}
const store = configureStore();
export default store;
