import React from "react";
import IconButton from "@material-ui/core/IconButton";
import StopIcon from "@material-ui/icons/Stop";
import { HttpService } from "../../http-service/HttpService";

const StopClassificationButton = ({
  classificationRoundId,
  memoizedFetch,
  setLoading,
  actionStatus,
}) => {
  const handleStop = () => {
    setLoading(true);
    HttpService.finishClassificationRound(classificationRoundId)
      .then(() => {
        actionStatus("stopSuccess");
        memoizedFetch();
        setLoading(false);
      })
      .catch(() => {
        actionStatus("stopError");
        setLoading(false);
      });
  };

  return (
    <IconButton onClick={() => handleStop()}>
      <StopIcon />
    </IconButton>
  );
};

export default StopClassificationButton;
