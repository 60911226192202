import React from "react";
import { makeStyles } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";

const EmailSent = ({ intl }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.heading} variant="h4" align="center">
        {translateValue(intl, "forgotPassword.resetPassword")}
      </Typography>
      <Typography variant="h6" align="center">
        {translateValue(intl, "forgotPassword.checkMailbox")}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: theme.spacing(4, 0, 4, 0),
  },
  container: {
    marginBottom: theme.spacing(6),
    maxWidth: "500px",
  },
}));

export default injectIntl(EmailSent);
