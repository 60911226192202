import React from "react";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogBase = (props) => {
  const {
    handleClose,
    handleSubmit,
    handleReset,
    open,
    intl,
    title,
    submitText,
    children,
    isValid,
    maxWidth,
    fullWidth,
  } = props;
  const classes = useStyles();

  const closeDialog = () => {
    handleReset();
    handleClose();
  };

  const width = maxWidth ? maxWidth : "xs";

  return (
    <Dialog
      onClose={closeDialog}
      open={open}
      maxWidth={width}
      fullWidth={fullWidth}
      disableBackdropClick
    >
      <DialogTitle onClose={closeDialog}>{title}</DialogTitle>
      <MuiDialogContent dividers className={classes.content}>
        {children}
      </MuiDialogContent>
      <MuiDialogActions>
        <Button onClick={closeDialog} color="primary">
          {translateValue(intl, "button.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid}
        >
          {submitText}
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
}));

export default injectIntl(DialogBase);
