import React from "react";
import { makeStyles } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import AuthWrapper from "../AuthWrapper";

const EmailSent = ({ intl }) => {
  const classes = useStyles();

  return (
    <AuthWrapper type="login">
      <div className={classes.container}>
        <Typography className={classes.heading} variant="h4" align="center">
          {translateValue(intl, "register.confirmEmail")}
        </Typography>
        <Typography variant="h6" align="center">
          {translateValue(intl, "register.checkMailbox")}
        </Typography>
      </div>
    </AuthWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: theme.spacing(4, 0, 4, 0),
  },
  container: {
    maxWidth: "400px",
    marginBottom: theme.spacing(12),
  },
}));

export default injectIntl(EmailSent);
