import React from "react";
import Button from "@material-ui/core/Button";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";

export const UploadButton = ({ children, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      component="label"
      className={classes.uploadButton}
      startIcon={<CloudUpload />}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    margin: "10px 0px",
  },
}));
