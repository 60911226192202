import axios from "axios";
import qs from "qs";
import { setExpirationDate, userLoggedOut } from "../actions/Actions";
import { timestampToDate } from "../util/Formatter";
import jwt_decode from "jwt-decode";
import { loginErrors } from "../auth/login/loginErrors";

axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
};

export function configureInterceptor(dispatch) {
  axios.interceptors.response.use(
    (response) => {
      if (response.headers.authorization) {
        // override with new token
        localStorage.setItem("token", `${response.headers.authorization}`);
        const decodedToken = jwt_decode(response.headers.authorization);
        const expiration = decodedToken.exp;

        if (expiration) {
          const expirationDate = timestampToDate(expiration);
          dispatch(setExpirationDate(expirationDate));
        }
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        dispatch(userLoggedOut());
        window.history.pushState("", "", "/");
      }
      return Promise.reject(error);
    }
  );
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  });
}
export class HttpService {
  static authHost = process.env.REACT_APP_AUTH_API_URL;
  static backendHost = process.env.REACT_APP_BACKEND_API_URL;
  static buildEnv = process.env.REACT_APP_BUILD_ENV;

  // ------------------------------------ USERS ------------------------------------------------ //

  static paginateUsers(limit, page) {
    return axios
      .get(HttpService.backendHost + `/user`, {
        params: {
          offset: page,
          size: limit,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateRole(updateUserRoleRequest) {
    return axios
      .put(HttpService.backendHost + `/user/${updateUserRoleRequest.userId}/role`, {
        roles: updateUserRoleRequest.body,
      })
      .then((res) => {
        return res.data;
      });
  }

  static getLoggedInUser() {
    return axios.get(HttpService.backendHost + `/user/self`).then((res) => {
      return res.data;
    });
  }

  static updateCurrentUser(updateUserData) {
    return axios.put(HttpService.backendHost + `/user/self`, updateUserData).then((res) => {
      return res.data;
    });
  }

  static activateUser(userId, code) {
    return axios
      .get(HttpService.backendHost + `/user/activate/${userId}/${code}`)
      .then((response) => {
        return response;
      });
  }

  // ------------------------------------ AUTH ------------------------------------------------ //

  static loginWithGoogleCode(code) {
    let url = HttpService.authHost + `/user/login?code=${code}&createIfNotExists=true`;
    if (HttpService.buildEnv === "development") {
      url =
        HttpService.authHost +
        `/user/login?code=${code}&createIfNotExists=true&uri=http://localhost:3000`;
    }

    return axios.post(url).then((res) => {
      let user = res.data;
      // temporary solution for classifier
      if (user.currentGroup.name === "CLASSIFIER") {
        throw new Error("classifier");
      } else {
        return res.data;
      }
    });
  }

  static loginWithEmail(loginUserDTO) {
    return axios
      .post(HttpService.authHost + `/user/clebre/login`, loginUserDTO)
      .then((res) => {
        let user = res.data;
        // temporary solution for classifier
        if (user.currentGroup.name === "CLASSIFIER") {
          throw new Error("classifier");
        } else if (user) {
          return user;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          throw new Error(loginErrors.userNotActive.status);
        } else {
          throw error;
        }
      });
  }

  static registerUser(registrationData) {
    return axios
      .post(HttpService.authHost + `/user/register`, registrationData)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static resetPassword(email) {
    const encodedEmail = encodeURIComponent(email);
    return axios
      .get(HttpService.backendHost + `/user/resetPassword?email=${encodedEmail}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static setNewPassword(resetPasswordDTO) {
    return axios
      .post(HttpService.backendHost + `/user/resetPassword`, resetPasswordDTO)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // ------------------------------------ PROFILES ------------------------------------------------ //

  static paginateProfiles(offset, limit, sortOrder, sortField) {
    const params = {
      sortOrder: sortOrder.toUpperCase(),
      sortField: sortField,
      offset: offset,
      size: limit,
      filters: [],
    };

    return axios
      .get(HttpService.backendHost + `/profile`, {
        params: {
          // encode to Base64
          requestQuery: btoa(JSON.stringify(params)),
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getProfileById(profileId) {
    return axios.get(HttpService.backendHost + `/profile/${profileId}`).then((res) => {
      return res.data;
    });
  }

  static addNewProfile(profileData) {
    return axios
      .post(HttpService.backendHost + `/profile`, profileData)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static editProfile(profileData, profileId) {
    return axios
      .patch(HttpService.backendHost + `/profile/${profileId}`, profileData)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static getUserProfiles() {
    return axios.get(HttpService.backendHost + `/profile`).then((res) => {
      return res.data;
    });
  }

  static validatePesel(pesel) {
    return axios.get(HttpService.backendHost + `/validators/pesel/${pesel}`).then((response) => {
      return response.data;
    });
  }

  // ------------------------------------ SENSORS ------------------------------------------------ //

  static paginateSensors(limit, page) {
    return axios
      .get(HttpService.backendHost + `/sensor`, {
        params: {
          page: page,
          size: limit,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getSensor(serialNumber) {
    return axios.get(HttpService.backendHost + `/sensor/${serialNumber}`).then((response) => {
      return response.data;
    });
  }

  static createSensor(newSensorRequest) {
    return axios.post(HttpService.backendHost + `/sensor`, newSensorRequest).then((res) => {
      return res.data;
    });
  }

  static updateSensor(sensorSerialNumber, updateSensorRequest) {
    return axios
      .put(HttpService.backendHost + `/sensor/${sensorSerialNumber}`, updateSensorRequest)
      .then((res) => {
        return res.data;
      });
  }

  // ------------------------------------ EXAMINATIONS ------------------------------------------------ //

  static paginateExaminations(offset, size, searchPhrase, sortField, sortOrder) {
    return axios
      .get(HttpService.backendHost + `/examination`, {
        params: {
          offset: offset,
          size: size,
          searchPhrase: searchPhrase,
          sortField: sortField,
          sortOrder: sortOrder,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static findExaminationById(examinationId) {
    return axios.get(HttpService.backendHost + `/examination/${examinationId}`).then((res) => {
      return res.data;
    });
  }

  static findExaminationByRecordId(sensorSerialNumber, recordId) {
    return axios
      .get(HttpService.backendHost + `/examination/${sensorSerialNumber}/${recordId}`)
      .then((res) => {
        return res.data;
      });
  }

  static deleteExaminationFile(examinationId, fileId) {
    return axios
      .delete(HttpService.backendHost + `/file/${examinationId}/${fileId}`)
      .then((res) => {
        return res.data;
      });
  }

  static downloadExaminationFile(examinationId, fileId) {
    return axios
      .get(HttpService.backendHost + `/file/${examinationId}/${fileId}`, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      });
  }

  static findExaminationFiles(sensorSerialNumber, recordId) {
    return axios
      .get(HttpService.backendHost + `/file/${sensorSerialNumber}/${recordId}`)
      .then((res) => {
        return res.data;
      });
  }

  static uploadExamination(base64examinationId, zipFile, jsnFile, profileId, setUploadStatus) {
    const examinationData = new FormData();

    examinationData.append("file", zipFile, `${base64examinationId}.zip`);
    examinationData.append("jsn", jsnFile, `${base64examinationId}.json`);

    return axios
      .post(HttpService.backendHost + `/batch/profile/${profileId}`, examinationData, {
        timeout: 1000000,
        onUploadProgress: (ProgressEvent) => {
          setUploadStatus((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static checkExaminationStatus(examinationId) {
    return axios
      .get(HttpService.backendHost + `/examination/${examinationId}/status`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static initiateFileUpload(examinationId, fileData) {
    return axios
      .post(HttpService.backendHost + `/file/${examinationId}/block`, fileData)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static uploadFileBlock(examinationId, examinationFileId, blockNumber, block, extension) {
    const fileBlock = new FormData();
    fileBlock.append("file", block, `chunk.${extension}`);

    return axios
      .put(
        HttpService.backendHost +
          `/file/${examinationId}/block/${examinationFileId}/${blockNumber}`,
        fileBlock
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static commitFileUpload(examinationId, examinationFileId) {
    return axios
      .post(HttpService.backendHost + `/file/${examinationId}/block/${examinationFileId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static getFileBlocks(examinationId, examinationFileId) {
    return axios
      .get(HttpService.backendHost + `/file/${examinationId}/block/${examinationFileId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // ------------------------------------ FIRMWARE ------------------------------------------------ //

  static downloadPackage(packageId) {
    // Taken from https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743

    return axios({
      url: HttpService.backendHost + `/firmware/package/${packageId}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");

      link.href = url;
      link.target = "_blank";
      link.click();
    });
  }

  static listAllFirmware() {
    return axios.get(HttpService.backendHost + `/firmware/all`).then((res) => {
      return res.data;
    });
  }

  static saveFirmwareMetadata(newFirmwareRequest, hardwareRevision, version) {
    return axios
      .post(
        HttpService.backendHost + `/firmware/metadata/${hardwareRevision}/${version}`,
        newFirmwareRequest
      )
      .then((res) => {
        return res.data;
      });
  }

  static saveFirmwarePackage(firmwareFile, packageId) {
    const multipartFiles = new FormData();
    multipartFiles.append("package", firmwareFile);

    return axios
      .post(HttpService.backendHost + `/firmware/package/${packageId}`, multipartFiles)
      .then((res) => {
        return res.data;
      });
  }

  static updateFirmwareMetadata(metadata, id) {
    return axios.patch(HttpService.backendHost + `/firmware/${id}`, metadata).then((res) => {
      return res.data;
    });
  }

  // ------------------------------------ CLASSIFICATION ------------------------------------------------ //

  static fetchClassificationsSummaryList(offset, limit, sortOrder, sortField, filters) {
    const params = {
      sortOrder: sortOrder.toUpperCase(),
      sortField: sortField,
      offset: offset,
      size: limit,
      filters: filters,
    };

    return axios
      .get(HttpService.backendHost + `/classification/summary`, {
        params: {
          // encode to Base64
          requestQuery: btoa(JSON.stringify(params)),
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getClassificationRounds(examinationId) {
    return axios
      .get(HttpService.backendHost + `/classification/${examinationId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static startClassificationRound(examinationId, oldVersion) {
    return axios
      .post(HttpService.backendHost + `/classification/${examinationId}?old=${oldVersion}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static finishClassificationRound(classificationId) {
    return axios
      .delete(HttpService.backendHost + `/classification/${classificationId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  // ------------------------------------ VISUALIZATION ------------------------------------------------ //

  static getSegmentationData(sensorSerialNumber, recordId, episodeTypes, episodeSubTypes) {
    return axios
      .get(HttpService.backendHost + `/segmentation/metadata/list`, {
        params: {
          sensorSerialNumber: sensorSerialNumber,
          recordId: recordId,
          episodeTypes: episodeTypes,
          episodeSubTypes: episodeSubTypes,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getEnvelope(sensorSerialNumber, recordId) {
    return axios
      .get(HttpService.backendHost + `/envelope/${sensorSerialNumber}/${recordId}`)
      .then((res) => {
        return res.data;
      });
  }

  static getEpisodesToVisualize(sensorSerialNumber, recordId, episodeTypes, episodeSubTypes) {
    return axios
      .get(HttpService.backendHost + `/visualization/${sensorSerialNumber}/${recordId}`, {
        params: {
          episodeTypes: episodeTypes,
          episodeSubTypes: episodeSubTypes,
        },
      })
      .then((res) => {
        return res.data;
      });
  }
}
