import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

export const CircularOnBackdrop = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="inherit" size="5rem" />
    </Backdrop>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 10,
    color: theme.palette.primary.dark,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
}));
