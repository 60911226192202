import React, { Component } from "react";
import _ from "lodash";
import { withStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import StopIcon from "@material-ui/icons/Stop";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import { injectIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connectComponent } from "../../../actions/ActionUtils";
import { HttpService } from "../../../http-service/HttpService";
import { permissions, hasPermissions } from "../../../util/validatePermissions";
import { notifySuccess, notifyError } from "../../../notifications/ClebreNotifications";
import DateTimeValue from "../../../component/date-time-value/DateTimeValue";
import ClebreTableHeader from "../../../component/ClebreTable/ClebreTableHeader";
import { translateValue } from "../../../i18n/ClebreTranslator";
import { CircularOnBackdrop } from "../../../component/Progress/CircularOnBackdrop";
import { convertTimezone } from "../../../util/timezoneConverter";

class ExaminationClassification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasRequiredFiles: false,
      examinationId: this.props.examinationId,
      classificationRounds: [],
      activeRoundId: null,
      pendingRound: null,
      loading: false,
      classificationLoading: false,
    };
  }

  checkRequiredFiles = () => {
    const requiredFiles = { WAV: false, PAR: false };

    for (let fileType in requiredFiles) {
      const file = this.props.examinationFiles.filter((file) => file.fileTypes === fileType);
      if (!_.isEmpty(file) && file[0].uploaded === true) {
        requiredFiles[fileType] = true;
      }
    }
    if (requiredFiles.WAV && requiredFiles.PAR) {
      this.setState({ hasRequiredFiles: true });
    }
  };

  componentDidMount() {
    this.checkRequiredFiles();

    this.setState({ loading: true });
    this.fetchClassificationRounds(this.state.examinationId);
  }

  fetchClassificationRounds = (examinationId) => {
    HttpService.getClassificationRounds(examinationId)
      .then((classificationRounds) => {
        const activeRounds = classificationRounds.filter(
          (round) => round.classificationStatus === "IN_PROGRESS"
        );
        const pendingRounds = classificationRounds.filter(
          (round) => round.classificationStatus === "PENDING"
        );

        this.setState({
          loading: false,
          classificationRounds,
          activeRoundId: !_.isEmpty(activeRounds) ? activeRounds[0].classificationRoundId : null,
          pendingRound: pendingRounds.length > 0,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  startClassificationRound = (classificationVersion) => {
    const oldVersion = classificationVersion === "old";
    this.setState({ classificationLoading: true });
    HttpService.startClassificationRound(this.state.examinationId, oldVersion)
      .then(() => {
        this.fetchClassificationRounds(this.state.examinationId);
      })
      .catch(() => {
        notifyError(translateValue(this.props.intl, "expertClassification.errorAlert"));
      })
      .finally(() => {
        this.setState({ classificationLoading: false });
      });
  };

  finishClassificationRound = () => {
    this.setState({ classificationLoading: true });
    HttpService.finishClassificationRound(this.state.activeRoundId)
      .then(() => {
        notifySuccess(translateValue(this.props.intl, "expertClassification.successAlert"));
        this.fetchClassificationRounds(this.state.examinationId);
      })
      .catch(() => {
        notifyError(translateValue(this.props.intl, "expertClassification.errorAlert"));
      })
      .finally(() => {
        this.setState({ classificationLoading: false });
      });
  };

  render() {
    const headCells = [
      {
        id: "startDateTime",
        align: "left",
        label: translateValue(this.props.intl, "expertClassification.startDateTime"),
      },
      {
        id: "classificationStatus",
        align: "left",
        label: translateValue(this.props.intl, "expertClassification.classificationStatus"),
      },
      {
        id: "endDateTime",
        align: "left",
        label: translateValue(this.props.intl, "expertClassification.endDateTime"),
      },
    ];

    if (this.state.loading) {
      return <CircularProgress />;
    }

    if (!this.state.hasRequiredFiles) {
      return (
        <Typography variant="h6">
          {translateValue(this.props.intl, "expertClassification.noRequiredFiles")}
        </Typography>
      );
    }

    return (
      <>
        {hasPermissions(
          [permissions.EXPERT_CLASSIFICATION_GET, permissions.EXPERT_CLASSIFICATION_GET_GLOBAL],
          this.props.user
        ) && (
          <TableContainer>
            <Table>
              <ClebreTableHeader headCells={headCells} />
              <TableBody>
                {this.state.classificationRounds.map((round) => {
                  return (
                    <TableRow key={round.classificationRoundId}>
                      <TableCell component="th" scope="row">
                        <DateTimeValue
                          datetime={convertTimezone(round.startDateTime, this.props.timezone)}
                        />
                      </TableCell>
                      <TableCell>
                        {translateValue(
                          this.props.intl,
                          `expertClassification.${round.classificationStatus}`
                        )}
                      </TableCell>
                      <TableCell>
                        {round.endDateTime && (
                          <DateTimeValue
                            datetime={convertTimezone(round.endDateTime, this.props.timezone)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {this.state.classificationRounds.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      {translateValue(this.props.intl, "noDataFound")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {hasPermissions([permissions.EXPERT_CLASSIFICATION_FINISH], this.props.user) &&
          this.state.activeRoundId && (
            <Button
              disabled={this.state.classificationLoading}
              variant="contained"
              color="primary"
              className={this.props.classes.button}
              startIcon={<StopIcon />}
              onClick={() => this.finishClassificationRound()}
            >
              {translateValue(this.props.intl, "expertClassification.finish")}
            </Button>
          )}
        {hasPermissions([permissions.EXPERT_CLASSIFICATION_START], this.props.user) &&
          !this.state.activeRoundId &&
          !this.state.pendingRound && (
            <>
              <Button
                disabled={this.state.classificationLoading}
                variant="contained"
                color="primary"
                className={this.props.classes.oldClassificationButton}
                startIcon={<PlayArrowIcon />}
                onClick={() => this.startClassificationRound("old")}
              >
                {translateValue(this.props.intl, "expertClassification.start")}
              </Button>
              <Button
                disabled={this.state.classificationLoading}
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                startIcon={<ShuffleIcon />}
                onClick={() => this.startClassificationRound("new")}
              >
                {translateValue(this.props.intl, "classification.startWithEpisodeSampling")}
              </Button>
            </>
          )}
        {this.state.classificationLoading && <CircularOnBackdrop />}
      </>
    );
  }
}

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(4),
  },
  oldClassificationButton: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
});

export default withStyles(styles)(injectIntl(connectComponent(ExaminationClassification)));
