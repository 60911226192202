import moment from "moment-timezone";

export function formatDurationInSeconds(duration) {
  const hours = parseNumber(parseInt(duration / (60 * 60)));
  const minutes = parseNumber(parseInt((duration / 60) % 60));
  const seconds = parseNumber(parseInt(duration % 60));
  return (
    (hours ? hours : "00") + ":" + (minutes ? minutes : "00") + ":" + (seconds ? seconds : "00")
  );
}

export function parseNumber(number) {
  return number ? (number < 10 ? "0" : "") + number : "";
}

export function getAge(dateOfBirth) {
  if (dateOfBirth) {
    const currentDate = new Date();
    const formattedDateOfBirth = new Date(dateOfBirth);
    return currentDate.getFullYear() - formattedDateOfBirth.getFullYear();
  }
}

export function getBMI(height, weight) {
  if (height && weight) {
    const heightInMeters = height / 100;
    return (weight / (heightInMeters * heightInMeters)).toFixed(2);
  }
}

export function formatDate(date, format) {
  return moment(date).format(format);
}

export function getDuration(dateFrom, dateTo) {
  return (new Date(dateTo).getTime() - new Date(dateFrom).getTime()) / 1000;
}

export function timestampToDate(timestamp) {
  return new Date(timestamp * 1000);
}
