// Required files - files added to FileStatusTable

export const validateRequiredFiles = (validationState) => {
  for (let fileType in validationState.requiredFiles) {
    // JSN was validated earlier
    if (fileType === "JSN") {
      continue;
    }

    // Default values
    validationState.requiredFiles[fileType].valid = true;

    const needsValidation = checkIfNeedsValidation(validationState, fileType);
    if (!needsValidation) continue;

    // If we have JSN already uploaded, we need to check the name
    const validFileName = validateFileName(validationState, fileType);
    if (!validFileName) continue;

    // If JSN is invalid, all files with the same name are invalid from the same reason
    const validJsn = checkIfJsnValid(validationState, fileType);
    if (!validJsn) continue;
    // File is valid at this point
  }

  // Recalculate status
  recalculateReqiredFilesStatus(validationState);
};

const checkIfNeedsValidation = (validationState, fileType) => {
  if (!validationState.requiredFiles[fileType].file) {
    // No file - no validation
    return false;
  }

  if (!validationState.requiredFiles["JSN"].file) {
    // Nothing to validate against
    return false;
  }
  return true;
};

const validateFileName = (validationState, fileType) => {
  if (validationState.requiredFiles[fileType].name !== validationState.requiredFiles["JSN"].name) {
    validationState.requiredFiles[fileType].valid = false;
    validationState.requiredFiles[fileType].message = "examination.add.wrongFilename";
    return false;
  }
  return true;
};

const checkIfJsnValid = (validationState, fileType) => {
  if (!validationState.requiredFiles["JSN"].valid) {
    validationState.requiredFiles[fileType].valid = false;
    validationState.requiredFiles[fileType].message = validationState.requiredFiles["JSN"].message;
    return false;
  }
  return true;
};

const recalculateReqiredFilesStatus = (validationState) => {
  for (let fileType in validationState.requiredFiles) {
    // if there is no file
    if (!validationState.requiredFiles[fileType].file) {
      validationState.requiredFiles[fileType].status = "add";
      validationState.requiredFiles[fileType].message = "file.add";
      continue;
    }
    // if file is valid
    if (validationState.requiredFiles[fileType].valid) {
      validationState.requiredFiles[fileType].status = "success";
      validationState.requiredFiles[fileType].message = "";
      continue;
    }

    validationState.requiredFiles[fileType].status = "error";
  }
};
