import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UiActions from "./Actions";

function mapState(state) {
  return state;
}

function mapActions(dispatch) {
  return {
    actions: bindActionCreators(UiActions, dispatch),
  };
}

export function connectComponent(component) {
  return connect(mapState, mapActions)(component);
}
