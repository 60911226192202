import React, { Component } from "react";
import { saveAs } from "file-saver";
import _ from "lodash";
import { connectComponent } from "../../../actions/ActionUtils";
import examinationClebreFileTypes, { examinationPsgFileTypes } from "./ExaminationFileTypes";
import { HttpService } from "../../../http-service/HttpService";
import DeleteExaminationFile from "./DeleteExaminationFile";
import { injectIntl } from "react-intl";
import DateTimeValue from "../../../component/date-time-value/DateTimeValue";
import AddExaminationFile from "./AddExaminationFile";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import GetApp from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import ClebreTableHeader from "../../../component/ClebreTable/ClebreTableHeader";
import PaperBase from "../../../component/Paper/PaperBase";
import { translateValue } from "../../../i18n/ClebreTranslator";
import { hasPermissions, permissions } from "../../../util/validatePermissions";
import ProgressBar from "../../../component/Progress/ProgressBar";
import Toast from "../../../component/Toast/Toast";
class ExaminationFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUploadModal: false,
      uploadProgressPercentage: null,
      uploadStatus: null,
    };
  }

  setUploadProgressPercentage = (value) => {
    this.setState({ uploadProgressPercentage: value });
  };

  setUploadStatus = (value) => {
    this.setState({ uploadStatus: value });
  };

  fetchExamination() {
    this.props.findExaminationData(this.props.examinationId);
  }

  findFileDataOfType(examinationFileType) {
    return this.props.examinationFiles.find(
      (element) => element.fileTypes === examinationFileType.value
    );
  }

  parseLocalDateTime(dateTimeOption) {
    return <DateTimeValue datetime={dateTimeOption} />;
  }

  setFileTypeToDelete = (fileType, fileId) => {
    this.props.actions.showDeleteFileModal(fileType, fileId);
  };

  deleteFileModal = () => {
    return !_.isEmpty(this.props.fileTypeToDelete) ? (
      <DeleteExaminationFile
        examinationId={this.props.examinationId}
        fileId={this.props.fileId}
        onDeleteFinished={() => this.fetchExamination()}
      />
    ) : null;
  };

  downloadFile = (fileId, extension) => {
    HttpService.downloadExaminationFile(this.props.examinationId, fileId).then((file) =>
      saveAs(file, `${this.props.recordId}.${extension}`)
    );
  };

  bigFiles = ["WAV", "EDF"];

  downloadPossible = (fileType, fileData) => {
    return (
      hasPermissions(
        [permissions.FILE_DOWNLOAD_GLOBAL, permissions.FILE_DOWNLOAD],
        this.props.user
      ) &&
      !this.bigFiles.includes(fileType.extension) &&
      fileData.uploaded
    );
  };

  renderFiles(files) {
    const headCells = [
      { id: "fileType", label: translateValue(this.props.intl, "file.type") },
      {
        id: "fileAdDateTime",
        label: translateValue(this.props.intl, "file.addDateTime"),
      },
      {
        id: "fileAuthor",
        label: translateValue(this.props.intl, "file.author"),
      },
      { id: "status", label: translateValue(this.props.intl, "file.status") },
      {
        id: "comments",
        label: translateValue(this.props.intl, "file.comments"),
      },
      {
        id: "actions",
        label: translateValue(this.props.intl, "button.actions"),
      },
    ];

    return (
      <TableContainer>
        <Table>
          <ClebreTableHeader headCells={headCells} />
          <TableBody>
            {Object.keys(files).map((key, index) => {
              const fileType = files[key];
              const fileData = this.findFileDataOfType(fileType);
              return (
                <TableRow key={index} align="center">
                  {_.isEmpty(fileData)
                    ? this.renderEmptyFileRow(fileType)
                    : this.renderFileRow(fileType, fileData)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderEmptyFileRow = (fileType) => {
    return (
      <>
        <TableCell component="th" scope="row">
          {fileType.description}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {hasPermissions(
          [permissions.FILE_UPLOAD, permissions.FILE_UPLOAD_GLOBAL],
          this.props.user
        ) ? (
          <TableCell>
            <AddExaminationFile
              type="newUpload"
              fileToUpload={fileType}
              examinationId={this.props.examinationId}
              uploadProgressPercentage={this.state.uploadProgressPercentage}
              setUploadProgressPercentage={this.setUploadProgressPercentage}
              setUploadStatus={this.setUploadStatus}
              onUploadFinished={() => {
                this.fetchExamination();
              }}
            />
          </TableCell>
        ) : null}
      </>
    );
  };

  renderFileRow = (fileType, fileData) => {
    return (
      <>
        <TableCell component="th" scope="row">
          {fileType.description}
        </TableCell>
        <TableCell>{this.parseLocalDateTime(fileData.addedAt)}</TableCell>
        <TableCell>{fileData.addedBy}</TableCell>
        <TableCell>
          {fileData.uploaded
            ? translateValue(this.props.intl, "success")
            : translateValue(this.props.intl, "error")}
        </TableCell>
        <TableCell>
          {!fileData.uploaded ? translateValue(this.props.intl, "file.uploadErrorMsg") : ""}
        </TableCell>
        <TableCell>
          {this.downloadPossible(fileType, fileData) ? (
            <IconButton
              color="primary"
              aria-label="download file"
              onClick={() => this.downloadFile(fileData.id, fileType.extension)}
            >
              <Tooltip title={translateValue(this.props.intl, "file.download")}>
                <GetApp />
              </Tooltip>
            </IconButton>
          ) : null}
          {!fileData.uploaded ? (
            <AddExaminationFile
              type="resumeUpload"
              fileToUpload={fileType}
              fileId={fileData.id}
              examinationId={this.props.examinationId}
              uploadProgressPercentage={this.state.uploadProgressPercentage}
              setUploadProgressPercentage={this.setUploadProgressPercentage}
              setUploadStatus={this.setUploadStatus}
              onUploadFinished={() => {
                this.fetchExamination();
              }}
            />
          ) : null}
        </TableCell>
      </>
    );
  };

  render() {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PaperBase elevation={3}>
              <span className={"examinationFileHeader"}>CLEBRE</span>
              <div className={"examinationFiles"}>
                {this.renderFiles(examinationClebreFileTypes)}
              </div>
            </PaperBase>
          </Grid>
          <Grid item xs={12}>
            <PaperBase elevation={3}>
              <div className={"examinationFiles"}>{this.renderFiles(examinationPsgFileTypes)}</div>
              {this.deleteFileModal()}
            </PaperBase>
            {this.state.uploadProgressPercentage !== null && (
              <Backdrop className={this.props.classes.backdrop} open={true}>
                <PaperBase className={this.props.classes.progressContainer}>
                  <Typography variant="h6" className={this.props.classes.uploadMessage}>
                    {this.state.uploadProgressPercentage === 0
                      ? translateValue(this.props.intl, "file.prepareUpload")
                      : translateValue(this.props.intl, "file.uploadInProgress")}
                  </Typography>
                  {this.state.uploadProgressPercentage > 0 && (
                    <Typography variant="body1" className={this.props.classes.uploadMessage}>
                      {translateValue(this.props.intl, "file.uploadAlert")}
                    </Typography>
                  )}
                  <ProgressBar progress={this.state.uploadProgressPercentage} labelColor="black" />
                </PaperBase>
              </Backdrop>
            )}
          </Grid>
        </Grid>
        {this.state.uploadStatus && (
          <Toast
            severity={this.state.uploadStatus.status}
            message={translateValue(this.props.intl, `file.${this.state.uploadStatus.message}`)}
          />
        )}
      </>
    );
  }
}

const styles = (theme) => ({
  backdrop: {
    zIndex: 1500,
  },
  progressContainer: {
    width: "40vw",
    paddingBottom: theme.spacing(6),
  },
  uploadMessage: {
    padding: theme.spacing(1),
  },
});

export default withStyles(styles)(withRouter(injectIntl(connectComponent(ExaminationFiles))));
