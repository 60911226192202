import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

export const calculateAge = (dateOfBirth) => {
  if (!dateOfBirth) return null;

  const today = moment();
  const formattedDateOfBirth = moment(dateOfBirth);
  const age = today.diff(formattedDateOfBirth, "years");
  return age;
};

export const findPatientExaminations = (examinations) => {
  const patientExaminations = examinations.filter(
    (examination) => examination.participationType === "PATIENT"
  );
  return patientExaminations;
};

export const findExaminationDuration = (examination) => {
  let start = moment(examination.startDateTime, "YYYY-MM-DD hh:mm:ss");
  let end = moment(examination.endDateTime, "YYYY-MM-DD hh:mm:ss");

  let difference = end.diff(start);
  let duration = moment.duration(difference);
  let formattedDuration = duration.format("hh:mm:ss", {
    trim: false,
  });

  return formattedDuration;
};

export const capitalize = (string) => {
  const words = string.toLowerCase().split(" ");

  if (words) {
    const capitalized = words
      .map((word) => {
        if (word !== "") {
          return word[0].toUpperCase() + word.substring(1);
        } else return null;
      })
      .join(" ");

    return capitalized;
  }
};

export const getTodayDate = () => {
  return new Date().toISOString().split("T")[0];
};

export const getYesterdayDate = () => {
  let today = new Date();
  let yesterday = today.setDate(today.getDate() - 1);
  return new Date(yesterday).toISOString().split("T")[0];
};

// React doesn't accept null as input value
const emptyStringToNull = (values) => {
  let valuesWithNull = {};
  for (const [key, value] of Object.entries(values)) {
    if (value === "") {
      valuesWithNull[key] = null;
    } else {
      valuesWithNull[key] = values[key];
    }
  }
  return valuesWithNull;
};

const validatePhoneNumberLength = (values) => {
  if (values["phoneNumber"] && values["phoneNumber"].length <= 5) {
    values["phoneNumber"] = null;
  }
  return values;
};

export const processFormValues = (values) => {
  const valuesWithNull = emptyStringToNull(values);
  const finalValues = validatePhoneNumberLength(valuesWithNull);

  return finalValues;
};

export const renderWithNarrowedDecimalPoints = (number, decimalPoints) => {
  return (Math.round(number * 100) / 100).toFixed(decimalPoints);
};
