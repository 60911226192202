import React from "react";
import { connectComponent } from "../../actions/ActionUtils";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PaperBase from "../../component/Paper/PaperBase";
import ApplyFilterButton from "../../component/Button/ApplyFilterButton";
import SearchInput from "../../component/input/SearchInput";
import DateInput from "../../component/input/DateInput";

const FilterPanel = (props) => {
  const classes = useStyles();

  return (
    <PaperBase classes={{ root: classes.filterArea }}>
      <Typography variant="h6">{translateValue(props.intl, "filters")}</Typography>
      <div className={classes.searchBoxes}>
        <SearchInput label={translateValue(props.intl, "profile.lastAndFirstNAme")} />
        <SearchInput label={translateValue(props.intl, "profile.govIdentification")} />
      </div>
      <Accordion classes={{ root: classes.moreFilters }}>
        <AccordionSummary>
          <Typography>{translateValue(props.intl, "filters.more")}</Typography>
          <ExpandMoreIcon />
        </AccordionSummary>
        <AccordionDetails className={classes.expandedFilters}>
          <Typography variant="body2">
            {translateValue(props.intl, "filters.lastExaminationDate")}
          </Typography>
          <div className={classes.dateFilters}>
            <DateInput
              label={translateValue(props.intl, "filters.from")}
              InputProps={{
                inputProps: {
                  min: "2000-01-01",
                  max: new Date().toISOString().split("T")[0],
                },
              }}
            />
            <DateInput
              label={translateValue(props.intl, "filters.to")}
              InputProps={{
                inputProps: {
                  min: "2000-01-01",
                  max: new Date().toISOString().split("T")[0],
                },
              }}
            />
            <ApplyFilterButton>{translateValue(props.intl, "button.filter")}</ApplyFilterButton>
          </div>
        </AccordionDetails>
      </Accordion>
    </PaperBase>
  );
};

const useStyles = makeStyles((theme) => ({
  filterArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  searchBoxes: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  moreFilters: {
    boxShadow: "none",
    border: "none",
    marginTop: theme.spacing(4),
  },
  dateFilters: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "flex-end",
  },
  expandedFilters: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default injectIntl(connectComponent(FilterPanel));
