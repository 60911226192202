export const examinationClebreFileTypes = {
  WAV: {
    value: "WAV",
    extension: "WAV",
    description: "Wav",
    messageId: "file.wav",
    acceptExtension: ".wav",
  },
  JSN: {
    value: "JSN",
    extension: "JSN",
    description: "Jsn",
    messageId: "file.jsn",
    acceptExtension: ".jsn",
  },
  ENV: {
    value: "ENV",
    extension: "ENV",
    description: "Env",
    messageId: "file.env",
    acceptExtension: ".env",
  },
  ACC: {
    value: "ACC",
    extension: "ACC",
    description: "Acc",
    messageId: "file.acc",
    acceptExtension: ".acc",
  },
  LOG: {
    value: "LOG",
    extension: "LOG",
    description: "Log",
    messageId: "file.log",
    acceptExtension: ".log",
  },
  PAR: {
    value: "PAR",
    extension: "PAR",
    description: "Par",
    messageId: "file.par",
    acceptExtension: ".par",
  },
  POX: {
    value: "POX",
    extension: "POX",
    description: "Pox",
    messageId: "file.pox",
    acceptExtension: ".pox",
  },
};

export const examinationPsgFileTypes = {
  EDF: {
    value: "EDF",
    extension: "EDF",
    description: "Edf",
    messageId: "file.edf",
    acceptExtension: ".edf",
  },
  PSG_APNEAS: {
    value: "PSG_APNEAS",
    extension: "PSG_APNEAS.XLS",
    description: "Apneas xls",
    messageId: "file.psgApneas",
    acceptExtension: ".xls, .xlsx",
  },
  PSG_SLEEP_PHASES: {
    value: "PSG_SLEEP_PHASES",
    extension: "PSG_SLEEP_PHASES.XLS",
    description: "Sleep phases xls",
    messageId: "file.psgSleepPhases",
    acceptExtension: ".xls, .xlsx",
  },
  PSG_POSITION: {
    value: "PSG_POSITION",
    extension: "PSG_POSITION.XLS",
    description: "Position xls",
    messageId: "file.psgPosition",
    acceptExtension: ".xls, .xlsx",
  },
};

export default examinationClebreFileTypes;
