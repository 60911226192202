import React, { Component } from "react";
import { HttpService } from "../http-service/HttpService";
import { connectComponent } from "../actions/ActionUtils";
import { injectIntl } from "react-intl";
import ClebreFormModal from "../component/clebre-form/ClebreFormModal";
import { translateValue } from "../i18n/ClebreTranslator";
import { SensorStatuses } from "./SensorEnums";
import { UploadButton } from "../component/Button/UploadButton";
import { FormInputBase } from "../component/input/FormInputBase";

class AddSensor extends Component {
  constructor(props) {
    super(props);

    this.enums = {
      sensorStatus: SensorStatuses.map((value) => ({
        value: value,
        label: translateValue(this.props.intl, `sensor.sensorStatus.${value}`),
      })),
    };

    this.state = {
      serialNumber: "",
      sensorStatus: "",
      firmwareVersion: "",
      hardwareRevision: "",
      customLabel: "",
      macAddress: "",
      showAddSensorModal: false,
    };
  }

  saveSensor = (event) => {
    const newSensorRequest = {
      serialNumber: this.state.serialNumber,
      sensorStatus: this.state.sensorStatus,
      firmwareVersion: this.state.firmwareVersion,
      hardwareRevision: this.state.hardwareRevision,
      customLabel: this.state.customLabel,
      createdDate: new Date().toISOString(),
      createOwnership: false,
      macAddress: this.state.macAddress,
    };

    HttpService.createSensor(newSensorRequest).then((data) => {
      this.handleAddModalClose();
      this.props.loadSensors();
    });
  };

  onChangeSensorStatus(event) {
    this.setState({
      sensorStatus: event.target.value,
    });
  }

  handleAddModalShow() {
    this.setState({ showAddSensorModal: true });
  }

  handleAddModalClose() {
    this.setState({ showAddSensorModal: false });
  }

  renderForm() {
    return (
      <div className={"addSensorForm"}>
        <div>
          <FormInputBase
            required
            label={translateValue(this.props.intl, "sensor.serialNumber")}
            value={this.state.serialNumber ? this.state.serialNumber : ""}
            onChange={(event) => this.setState({ serialNumber: event.target.value })}
          />
        </div>
        <div>
          <FormInputBase
            required
            select
            label={translateValue(this.props.intl, "sensor.sensorStatus")}
            value={this.state.sensorStatus}
            onChange={(event) => {
              this.onChangeSensorStatus(event);
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value={""}></option>
            {this.enums.sensorStatus.map((sensorStatus) => (
              <option key={sensorStatus.value} value={sensorStatus.value}>
                {sensorStatus.label}
              </option>
            ))}
          </FormInputBase>
        </div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "sensor.firmwareVersion")}
            value={this.state.firmwareVersion}
            onChange={(event) => this.setState({ firmwareVersion: event.target.value })}
          />
        </div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "sensor.hardwareRevision")}
            value={this.state.hardwareRevision}
            onChange={(event) => this.setState({ hardwareRevision: event.target.value })}
          />
        </div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "sensor.macAddress")}
            value={this.state.macAddress}
            onChange={(event) => this.setState({ macAddress: event.target.value })}
          />
        </div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "sensor.customLabel")}
            value={this.state.customLabel}
            onChange={(event) => this.setState({ customLabel: event.target.value })}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"sensorModal"}>
        <UploadButton onClick={() => this.handleAddModalShow()}>
          {translateValue(this.props.intl, "button.addSensor")}
        </UploadButton>
        <ClebreFormModal
          i18nLabelTitle="modal.headingAddSensor"
          modalClass="addSensor"
          formBody={this.renderForm()}
          onSave={(event) => this.saveSensor(event)}
          onHide={() => this.handleAddModalClose()}
          show={this.state.showAddSensorModal}
        />
      </div>
    );
  }
}

export default injectIntl(connectComponent(AddSensor));
