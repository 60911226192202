import React, { useCallback, useEffect, useState } from "react";
import { HttpService } from "../../http-service/HttpService";
import { connectComponent } from "../../actions/ActionUtils";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { calculateAge } from "../../util/helpers";
import { hasPermissions, permissions } from "../../util/validatePermissions";
import { formatDate } from "../../util/Formatter";
import { convertTimezone } from "../../util/timezoneConverter";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ClebreTableHeader from "../../component/ClebreTable/ClebreTableHeader";
import PaperBase from "../../component/Paper/PaperBase";
import FilterPanel from "./FilterPanel";
import AddButton from "../../component/Button/AddButton";
import ClebreTablePagination from "../../component/ClebreTable/ClebreTablePagination";
import { generateColumnField } from "../../component/ClebreTable/generateTableColumn";
import NoResultsRow from "../../component/ClebreTable/NoResultsRow";
import AddProfileDialog from "./AddProfileDialog";
import Toast from "../../component/Toast/Toast";
import { CircularOnBackdrop } from "../../component/Progress/CircularOnBackdrop";

const ProfilesPage = (props) => {
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("lastName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [profiles, setProfiles] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAddProfileDialogOpen, setAddProfileDialogOpen] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const history = useHistory();

  const classes = useStyles();

  const memoizedFetch = useCallback(() => {
    setLoading(true);
    HttpService.paginateProfiles(page, limit, sortOrder, sortField)
      .then((response) => {
        setProfiles(response.content);
        setTotalRecords(response.totalElements);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [limit, page, sortField, sortOrder]);

  useEffect(() => {
    memoizedFetch();
  }, [memoizedFetch]);

  const findProfileData = (profile) => {
    const gender = profile.gender ? translateValue(props.intl, profile.gender.toLowerCase()) : null;
    const age = profile.dateOfBirth ? calculateAge(profile.dateOfBirth) : null;
    const formattedLastExaminationDate = profile.dateOfLastExamination
      ? formatDate(
          convertTimezone(profile.dateOfLastExamination, props.timezone),
          "YYYY-MM-DD HH:mm:ss"
        )
      : null;
    const lastExaminationResult = profile.resultOfLastExamination;
    const numberOfExaminations = profile.countOfExaminations;

    return {
      gender,
      age,
      formattedLastExaminationDate,
      lastExaminationResult,
      numberOfExaminations,
    };
  };

  const openAddProfileDialog = () => {
    setAddProfileDialogOpen(true);
  };

  const closeAddProfileDialog = () => {
    setAddProfileDialogOpen(false);
  };

  const handleRowClick = (event) => {
    const profileId = event.currentTarget.id;
    history.push(`/profiles/${profileId}`);
  };

  const headCellValues = [
    {
      name: "lastAndFirstNAme",
      label: translateValue(props.intl, "profile.lastAndFirstNAme"),
    },
    { name: "gender", label: translateValue(props.intl, "profile.gender") },
    { name: "age", label: translateValue(props.intl, "profile.age") },
    {
      name: "govIdentification",
      label: translateValue(props.intl, "profile.govIdentification"),
    },
    {
      name: "numberOfExaminations",
      label: translateValue(props.intl, "profile.numberOfExaminations"),
    },
    {
      name: "dateOfLastExamination",
      label: translateValue(props.intl, "profile.dateOfLastExamination"),
    },
    {
      name: "lastExaminationResult",
      label: translateValue(props.intl, "profile.lastExaminationResult"),
    },
  ];

  const headCells = headCellValues.map((column) => {
    return generateColumnField(
      column.name,
      column.label,
      true,
      sortOrder,
      sortField,
      setSortOrder,
      setSortField
    );
  });

  return (
    <div className={classes.pageContent}>
      <div className={classes.pageHeader}>
        <Typography variant="h4">{translateValue(props.intl, "profiles")}</Typography>
        {hasPermissions(
          [permissions.PROFILE_ADD, permissions.PROFILE_ADD_VALIDATED],
          props.user
        ) && (
          <AddButton onClick={openAddProfileDialog}>
            {translateValue(props.intl, "button.addProfile")}
          </AddButton>
        )}
      </div>
      <AddProfileDialog
        open={isAddProfileDialogOpen}
        handleClose={closeAddProfileDialog}
        setSubmissionStatus={setSubmissionStatus}
        fetchProfiles={memoizedFetch}
      />
      {submissionStatus === "error" && (
        <Toast severity="error" message={translateValue(props.intl, "addProfile.error")} />
      )}
      {submissionStatus === "success" && (
        <Toast severity="success" message={translateValue(props.intl, "addProfile.success")} />
      )}
      <FilterPanel />
      <PaperBase classes={{ root: classes.tableContainer }}>
        <Table>
          <ClebreTableHeader headCells={headCells} />
          <TableBody>
            {profiles.map((profile) => {
              const {
                gender,
                age,
                formattedLastExaminationDate,
                numberOfExaminations,
                lastExaminationResult,
              } = findProfileData(profile);

              return (
                <TableRow
                  key={profile.profileId}
                  id={profile.profileId}
                  onClick={handleRowClick}
                  hover
                  className={classes.tableRowHover}
                >
                  <TableCell>{`${profile.lastName} ${profile.firstName}`}</TableCell>
                  <TableCell>{gender}</TableCell>
                  <TableCell>{age}</TableCell>
                  <TableCell>{profile.govIdentification}</TableCell>
                  <TableCell>{numberOfExaminations}</TableCell>
                  <TableCell>{formattedLastExaminationDate}</TableCell>
                  <TableCell>{lastExaminationResult}</TableCell>
                </TableRow>
              );
            })}
            {totalRecords === 0 && !loading && <NoResultsRow span={7} />}
          </TableBody>
        </Table>
        <ClebreTablePagination
          page={page}
          limit={limit}
          totalRecords={totalRecords}
          setLimit={setLimit}
          setPage={setPage}
        />
      </PaperBase>
      {loading && <CircularOnBackdrop />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: "0px",
  },
  pageHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageContent: {
    marginTop: theme.spacing(4),
  },
  tableRowHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default injectIntl(connectComponent(ProfilesPage));
