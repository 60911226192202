import React, { Component } from "react";
import { HttpService } from "../http-service/HttpService";
import { connectComponent } from "../actions/ActionUtils";
import AddFirmware from "./AddFirmware";
import EditFirmware from "./EditFirmware";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DateTimeValue from "../component/date-time-value/DateTimeValue";
import GetApp from "@material-ui/icons/GetApp";
import Edit from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import ClebreTableHeader from "../component/ClebreTable/ClebreTableHeader";
import { translateValue } from "../i18n/ClebreTranslator";
import { injectIntl } from "react-intl";
import { convertTimezone } from "../util/timezoneConverter";
import TablePagination from "@material-ui/core/TablePagination";
import { CircularOnBackdrop } from "../component/Progress/CircularOnBackdrop";

class Firmware extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firmware: [],
      limit: 10,
      totalRecords: 0,
      page: 0,
      loading: false,
    };
  }

  componentDidMount() {
    this.loadVersions();
  }

  downloadPackage(packageId) {
    HttpService.downloadPackage(packageId);
  }

  loadVersions() {
    this.setState({ loading: true });
    HttpService.listAllFirmware()
      .then((response) => {
        this.setState({
          firmware: response,
          totalRecords: response.length,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => this.loadVersions());
  };

  handleRowsPerPage = (event) => {
    this.setState({ limit: event.target.value }, () => this.loadVersions());
  };

  setFirmwareToEdit = (firmware) => {
    this.props.actions.showEditFirmwareModal(firmware);
  };

  renderFirmwareTable() {
    const headCells = [
      {
        id: "firmwareHardwareRevision",
        align: "left",
        label: translateValue(this.props.intl, "firmware.hardwareRevision"),
      },
      {
        id: "firmwareVersion",
        label: translateValue(this.props.intl, "firmware.version"),
      },
      {
        id: "firmwareReleaseDate",
        label: translateValue(this.props.intl, "firmware.releaseDate"),
      },
      {
        id: "firmwareChangelog",
        label: translateValue(this.props.intl, "firmware.changelog"),
      },
      {
        id: "firmwareMd5",
        label: translateValue(this.props.intl, "firmware.md5"),
      },
      {
        id: "actions",
        label: translateValue(this.props.intl, "button.actions"),
      },
    ];
    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <ClebreTableHeader headCells={headCells} />
            <TableBody>
              {this.state.firmware.map((firmware) => (
                <TableRow key={firmware.hardwareRevision + firmware.firmwareVersion}>
                  <TableCell component="th" scope="row">
                    {firmware.hardwareRevision}
                  </TableCell>
                  <TableCell>{firmware.firmwareVersion}</TableCell>
                  <TableCell>
                    {
                      <DateTimeValue
                        datetime={convertTimezone(firmware.releaseDate, this.props.timezone)}
                      />
                    }
                  </TableCell>
                  <TableCell>{firmware.changelog}</TableCell>
                  <TableCell>{firmware.packageMD5}</TableCell>
                  <TableCell>
                    <div>
                      <IconButton
                        color="primary"
                        aria-label="download package"
                        onClick={() => this.downloadPackage(firmware.packageId)}
                      >
                        <GetApp />
                      </IconButton>
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        onClick={() => this.setFirmwareToEdit(firmware)}
                      >
                        <Edit />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {this.state.totalRecords === 0 && !this.state.loading && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    {translateValue(this.props.intl, "noDataFound")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          labelRowsPerPage={translateValue(this.props.intl, "table.rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) => {
            return ` ${from} - ${to} ${translateValue(this.props.intl, "of")} ${count}`;
          }}
          page={this.state.page}
          rowsPerPage={this.state.limit}
          count={this.state.totalRecords}
          onChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
          onChangeRowsPerPage={(event) => this.handleRowsPerPage(event)}
        />
        {this.state.loading && <CircularOnBackdrop />}
      </>
    );
  }

  render() {
    return (
      <div className={"firmware"}>
        {this.addFirmware()}
        {this.renderFirmwareTable()}
        {this.editFirmware()}
      </div>
    );
  }

  addFirmware() {
    return <AddFirmware loadFirmware={() => this.loadVersions()} />;
  }

  editFirmware() {
    return !_.isEmpty(this.props.firmwareToEdit) ? (
      <EditFirmware loadFirmware={() => this.loadVersions()} />
    ) : null;
  }
}

export default injectIntl(connectComponent(Firmware));
