import React, { useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { HttpService } from "../../http-service/HttpService";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { connectComponent } from "../../actions/ActionUtils";
import { makeStyles } from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import AuthWrapper from "../AuthWrapper";
import { registerValidationSchema } from "./registerValidationSchema";
import GoogleAuthButton from "../../component/googleAuth/GoogleAuthButton";
import PasswordInput from "../../component/input/PasswordInput";
import CheckboxWithLabel from "../../component/input/CheckboxWithLabel";
import { registerErrors } from "./registerErrors";
import { loginErrors } from "../login/loginErrors";

const RegisterForm = ({ intl, ...props }) => {
  const classes = useStyles();
  const [registerError, setRegisterError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imADoctor, setImADoctor] = useState(false);
  const [registerInfo, setRegisterInfo] = useState(null);

  const handleEmailRegister = (values, resetForm) => {
    setLoading(true);

    HttpService.registerUser({
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      role: imADoctor ? "DOCTOR" : "BASIC",
    })
      .then((user) => {
        props.actions.userLoggedIn(user);
        const tenMinutesFromNow = new Date().getTime() + 10 * 60000;
        props.actions.setExpirationDate(tenMinutesFromNow);
        setLoading(false);
        resetForm();
      })
      .catch((error) => {
        if (error.response.data.errorId === registerErrors.accountAlreadyExists.status) {
          setRegisterError(registerErrors.accountAlreadyExists.message);
        } else if (error.message === loginErrors.classifier.status) {
          props.actions.userLoggedOut();
          setRegisterInfo(loginErrors.classifier.message);
        } else {
          setRegisterError(registerErrors.genericError.message);
        }
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      role: "",
    },
    onSubmit: (values, { resetForm }) => {
      setRegisterError(null);
      handleEmailRegister(values, resetForm);
    },
    validationSchema: registerValidationSchema(intl),
    validateOnMount: true,
    isInitialValid: false,
  });

  const handleBlurAndTrim = (event) => {
    event.target.value = event.target.value.trim();
    formik.handleBlur(event);
    formik.handleChange(event);
  };

  const ToggleDoctorCheckbox = () => {
    setImADoctor(!imADoctor);
  };

  return (
    <AuthWrapper type="register">
      <GoogleAuthButton
        label={translateValue(intl, "register.withGoogle")}
        setError={setRegisterError}
        setInfo={setRegisterInfo}
        setLoading={setLoading}
        resetForm={formik.resetForm}
      />
      <Typography variant="h6" align="center">
        {translateValue(intl, "register.withEmail")}
      </Typography>
      {registerError && (
        <Alert className={classes.alert} severity="error">
          {translateValue(intl, `register.error.${registerError}`)}
        </Alert>
      )}
      {registerInfo && (
        <Alert className={classes.alert} severity="info">
          {translateValue(intl, `login.error.${registerInfo}`)}
        </Alert>
      )}
      {loading && <CircularProgress />}
      <div className={classes.personalData}>
        <TextField
          name="firstName"
          variant="outlined"
          required
          disabled={loading}
          label={translateValue(intl, "firstName")}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={handleBlurAndTrim}
          error={formik.errors.firstName && formik.touched.firstName}
          helperText={
            formik.errors.firstName && formik.touched.firstName ? formik.errors.firstName : null
          }
          inputProps={{ style: { textTransform: "capitalize" } }}
        />
        <TextField
          name="lastName"
          variant="outlined"
          required
          disabled={loading}
          label={translateValue(intl, "lastName")}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={handleBlurAndTrim}
          error={formik.errors.lastName && formik.touched.lastName}
          helperText={
            formik.errors.lastName && formik.touched.lastName ? formik.errors.lastName : null
          }
          inputProps={{ style: { textTransform: "capitalize" } }}
        />
      </div>
      <TextField
        name="email"
        variant="outlined"
        required
        fullWidth
        disabled={loading}
        label={translateValue(intl, "email")}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.email && formik.touched.email}
        helperText={formik.errors.email && formik.touched.email ? formik.errors.email : null}
      />
      <PasswordInput
        disabled={loading}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.password && formik.touched.password}
        helperText={
          formik.errors.password && formik.touched.password ? formik.errors.password : null
        }
      />
      <div className={classes.checkboxes}>
        <CheckboxWithLabel
          name="imADoctor"
          value={imADoctor}
          onChange={ToggleDoctorCheckbox}
          label={translateValue(intl, "register.imADoctor")}
        />
        <CheckboxWithLabel
          name="dataProcessingAgreement"
          value={formik.values.dataProcessingAgreement}
          onChange={formik.handleChange}
          label={translateValue(intl, "register.dataProcessingAgreement")}
        />
      </div>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!formik.isValid || loading}
        onClick={formik.handleSubmit}
      >
        {translateValue(intl, "signUp")}
      </Button>
      <Link to="/" className={classes.link}>
        {translateValue(intl, "register.alreadyHaveAccount")}
      </Link>
    </AuthWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  alert: {
    width: "100%",
  },
  checkboxes: {
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "column",
    margin: 0,
  },
  personalData: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",

    "& > *": {
      width: "48%",
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

export default injectIntl(connectComponent(RegisterForm));
