import * as Yup from "yup";
import {
  firstNameValidation,
  lastNameValidation,
  passwordValidation,
  emailValidation,
} from "../../validation/validationConstants";

export const registerValidationSchema = (intl) => {
  return Yup.object().shape({
    email: emailValidation(intl),
    password: passwordValidation(intl),
    firstName: firstNameValidation(intl),
    lastName: lastNameValidation(intl),
  });
};
