import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const Toast = ({ severity, message, intl, setUploadResult }) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const handleClose = () => {
    if (setUploadResult) {
      setUploadResult("");
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
      action={
        <IconButton color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Alert className={classes.toast} onClose={handleClose} severity={severity} elevation={3}>
        <AlertTitle>{translateValue(intl, `${severity}`)}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

const useStyles = makeStyles((theme) => ({
  toast: {
    width: "100vw",
  },
}));

export default injectIntl(Toast);
