import { selectJsn, validateJsn } from "./jsnValidation";
import { validateWithApiCalls } from "./asyncValidation";
import { validateSync } from "./syncValidation";

export const updateValidationState = (validationState, newFiles) => {
  // Reset state (apart from required files)
  validationState.sensorId.id = null;
  validationState.droppedFiles = {
    wrongExtension: [],
    wrongName: [],
    duplicates: [],
    tooBig: [],
  };

  let processedFiles = processFiles(newFiles);

  // prettier-ignore
  return selectJsn(validationState, processedFiles)
    .then(validateJsn)
    .then(validateWithApiCalls)
    .then(validateSync)
    .catch((error) => {
      // Catch all unexpected errors
      throw error;
    });
};

const processFiles = (files) => {
  const processedFiles = [];

  // Prepare files for validation
  files.forEach((file) => {
    const splitted = file.name.split(".");
    const fileExtension = splitted.pop().toUpperCase();
    let fileName = splitted.join(".");
    fileName = fileName.replace(/[^a-zA-Z0-9]/g, "_");
    processedFiles.push({
      name: fileName,
      extension: fileExtension,
      file: file,
    });
  });
  return processedFiles;
};
