import React, { Component } from "react";
import { connectComponent } from "../../../actions/ActionUtils";
import { HttpService } from "../../../http-service/HttpService";
import ClebreFormModal from "../../../component/clebre-form/ClebreFormModal";

class DeleteExaminationFile extends Component {
  closeModal = () => {
    this.props.actions.closeDeleteFileModal();
  };

  deleteFile = () => {
    HttpService.deleteExaminationFile(this.props.examinationId, this.props.fileId).then(() => {
      this.props.onDeleteFinished();
      this.closeModal();
    });
  };

  render() {
    return (
      <div>
        <ClebreFormModal
          i18nLabelTitle="modal.headingDeleteExaminationFile"
          modalClass="firmwareEdit"
          onSave={() => this.deleteFile()}
          show={!!this.props.fileTypeToDelete}
          onHide={() => this.closeModal()}
          saveLabel={"modal.yes"}
          closeLabel={"modal.no"}
        />
      </div>
    );
  }
}

export default connectComponent(DeleteExaminationFile);
