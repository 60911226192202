import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/";
import AuthWrapper from "../AuthWrapper";
import EmailSentView from "./EmailSentView";
import ResetView from "./ResetView";

const ForgotPasswordPage = ({ intl }) => {
  const [emailSent, setEmailSent] = useState(false);
  const classes = useStyles();

  return (
    <AuthWrapper type="login">
      {emailSent ? <EmailSentView /> : <ResetView setEmailSent={setEmailSent} />}
      <Link to="/" className={classes.backToLogin}>
        {translateValue(intl, "backToLogin")}
      </Link>
    </AuthWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  backToLogin: {
    marginBottom: theme.spacing(6),
    fontSize: "1rem",
  },
}));

export default injectIntl(ForgotPasswordPage);
