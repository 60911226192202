import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import Tooltip from "@material-ui/core/Tooltip";
import { injectIntl } from "react-intl";
import { HttpService } from "../../http-service/HttpService";
import { translateValue } from "../../i18n/ClebreTranslator";

const StartClassificationButton = ({
  examinationId,
  memoizedFetch,
  setLoading,
  actionStatus,
  version,
  intl,
}) => {
  const oldVersion = version === "old";

  const handleStart = () => {
    setLoading(true);
    HttpService.startClassificationRound(examinationId, oldVersion)
      .then(() => {
        actionStatus("startSuccess");
        memoizedFetch();
        setLoading(false);
      })
      .catch(() => {
        actionStatus("startError");
        setLoading(false);
      });
  };

  return (
    <Tooltip
      title={
        oldVersion
          ? translateValue(intl, "start")
          : translateValue(intl, "classification.startWithEpisodeSampling")
      }
    >
      <IconButton onClick={() => handleStart()}>
        {oldVersion ? <PlayCircleFilledIcon /> : <ShuffleIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default injectIntl(StartClassificationButton);
