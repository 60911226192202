import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { connectComponent } from "../../actions/ActionUtils";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { calculateAge, capitalize } from "../../util/helpers";
import { hasPermissions, permissions } from "../../util/validatePermissions";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import PaperBase from "../../component/Paper/PaperBase";
import EditProfileDialog from "./EditProfileDialog";
import Toast from "../../component/Toast/Toast";

const ProfileDataPanel = ({ intl, profile, user, setProfile }) => {
  const classes = useStyles();
  const [profileData, setProfileData] = useState({ sensorLeases: [] });
  const [isGeneralEditDialogOpen, setGeneralEditDialogOpen] = useState(false);
  const [isWeightEditDialogOpen, setWeightEditDialogOpen] = useState(false);
  const [isHeightEditDialogOpen, setHeightEditDialogOpen] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");

  const editPermission = hasPermissions(
    [permissions.PROFILE_EDIT, permissions.PROFILE_EDIT_GLOBAL],
    user
  );

  const findProfileData = () => {
    const gender = profile.gender ? profile.gender : "";
    const lastName = profile.lastName ? capitalize(profile.lastName) : "";
    const firstName = profile.firstName ? capitalize(profile.firstName) : "";
    const {
      profileId,
      govIdentification,
      dateOfBirth,
      phoneNumber,
      weight,
      height,
      sensorLeases,
    } = profile;

    return {
      profileId,
      firstName,
      lastName,
      gender,
      govIdentification,
      dateOfBirth,
      phoneNumber,
      weight,
      height,
      sensorLeases,
    };
  };

  useEffect(() => {
    const data = findProfileData();
    setProfileData(data);
  }, [profile]);

  const openEditDialog = (type) => {
    type === "general" && setGeneralEditDialogOpen(true);
    type === "weight" && setWeightEditDialogOpen(true);
    type === "height" && setHeightEditDialogOpen(true);
  };

  const closeEditDialog = (type) => {
    type === "general" && setGeneralEditDialogOpen(false);
    type === "weight" && setWeightEditDialogOpen(false);
    type === "height" && setHeightEditDialogOpen(false);
    setSubmissionStatus("");
  };

  const noData = translateValue(intl, "noData");

  return (
    <div className={classes.dataContainer}>
      <PaperBase className={classes.largePaper}>
        <div className={classes.dataColumn}>
          <div className={classes.dataItem}>
            <Typography variant="h6">{translateValue(intl, "profile.lastAndFirstNAme")}</Typography>
            <Typography
              variant="body1"
              className={classes.greyText}
            >{`${profileData.lastName} ${profileData.firstName}`}</Typography>
          </div>
          <div className={clsx(classes.dataItem, classes.bottomItem)}>
            <Typography variant="h6">
              {translateValue(intl, "profile.govIdentification")}
            </Typography>
            <Typography variant="body1" className={classes.greyText}>
              {profileData.govIdentification ? profileData.govIdentification : noData}
            </Typography>
          </div>
        </div>
        <div className={classes.dataColumn}>
          <div className={classes.dataItem}>
            <Typography variant="h6">{translateValue(intl, "profile.age")}</Typography>
            <Typography variant="body1" className={classes.greyText}>
              {profileData.dateOfBirth ? calculateAge(profileData.dateOfBirth) : noData}
            </Typography>
          </div>
          <div className={clsx(classes.dataItem, classes.bottomItem)}>
            <Typography variant="h6">{translateValue(intl, "profile.gender")}</Typography>
            <Typography variant="body1" className={classes.greyText}>
              {profileData.gender ? translateValue(intl, profileData.gender.toLowerCase()) : noData}
            </Typography>
          </div>
        </div>
        <div className={clsx(classes.dataColumn, classes.thinColumn)}>
          <div className={classes.dataItem}>
            <Typography variant="h6">{translateValue(intl, "profile.phoneNumber")}</Typography>
            <Typography variant="body1" className={classes.greyText}>
              {profileData.phoneNumber ? profileData.phoneNumber : noData}
            </Typography>
          </div>
        </div>
        {editPermission && (
          <div className={clsx(classes.dataColumn, classes.editColumn)}>
            <IconButton onClick={() => openEditDialog("general")} className={classes.icon}>
              <EditIcon />
            </IconButton>
            <EditProfileDialog
              type="general"
              open={isGeneralEditDialogOpen}
              handleClose={() => closeEditDialog("general")}
              setSubmissionStatus={setSubmissionStatus}
              profiledata={profileData}
              setProfile={setProfile}
              setProfileData={setProfileData}
            />
          </div>
        )}
      </PaperBase>
      <PaperBase className={classes.largePaper}>
        <div className={clsx(classes.dataColumn, classes.thinColumn)}>
          <div className={classes.dataItem}>
            <Typography variant="h6">{translateValue(intl, "profile.weight")}</Typography>
            <Typography variant="body1" className={classes.greyText}>
              {profileData.weight ? profileData.weight : noData}
            </Typography>
          </div>
        </div>
        {editPermission && (
          <div className={clsx(classes.dataColumn, classes.editColumn)}>
            <IconButton onClick={() => openEditDialog("weight")} className={classes.icon}>
              <EditIcon />
            </IconButton>
            <EditProfileDialog
              type="weight"
              open={isWeightEditDialogOpen}
              handleClose={() => closeEditDialog("weight")}
              setSubmissionStatus={setSubmissionStatus}
              profiledata={profileData}
              setProfileData={setProfileData}
            />
          </div>
        )}
        <div className={clsx(classes.dataColumn, classes.thinColumn)}>
          <div className={classes.dataItem}>
            <Typography variant="h6">{translateValue(intl, "profile.height")}</Typography>
            <Typography variant="body1" className={classes.greyText}>
              {profileData.height ? profileData.height : noData}
            </Typography>
          </div>
        </div>
        {editPermission && (
          <div className={clsx(classes.dataColumn, classes.editColumn)}>
            <IconButton onClick={() => openEditDialog("height")} className={classes.icon}>
              <EditIcon />
            </IconButton>
            <EditProfileDialog
              type="height"
              open={isHeightEditDialogOpen}
              handleClose={() => closeEditDialog("height")}
              setSubmissionStatus={setSubmissionStatus}
              profiledata={profileData}
              setProfileData={setProfileData}
            />
          </div>
        )}
      </PaperBase>
      <PaperBase className={classes.smallPaper}>
        <div className={classes.dataItem}>
          <Typography variant="h6">{translateValue(intl, "profile.lentSensors")}</Typography>
          {/* TODO: Adjust once the lease format is known */}
          {profileData.sensorLeases.length > 0 ? (
            profileData.sensorLeases.map((lease) => {
              return (
                <Typography variant="body1" className={classes.greyText}>
                  {lease}
                </Typography>
              );
            })
          ) : (
            <Typography variant="body1" className={classes.greyText}>
              {noData}
            </Typography>
          )}
        </div>
      </PaperBase>
      {submissionStatus === "error" && (
        <Toast severity="error" message={translateValue(intl, "editProfile.error")} />
      )}
      {submissionStatus === "success" && (
        <Toast severity="success" message={translateValue(intl, "editProfile.success")} />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    display: "flex",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  dataItem: {
    marginBottom: "auto",
    textAlign: "center",

    "&:last-child": {
      marginBottom: theme.spacing(2),
    },
  },
  bottomItem: {
    marginTop: theme.spacing(2),
  },
  greyText: {
    color: theme.palette.text.secondary,
  },
  largePaper: {
    maxWidth: "45%",
    display: "flex",
    flexDirection: "row",
    marginRight: theme.spacing(8),

    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(4),
      maxWidth: "40%",
    },
  },
  smallPaper: {
    maxWidth: "25%",
    display: "flex",
    flexDirection: "row",
    marginRight: theme.spacing(8),

    [theme.breakpoints.down("lg")]: {
      marginRight: 0,
      maxWidth: "20%",
    },
  },
  dataColumn: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(4),
  },
  editColumn: {
    marginLeft: "auto",
    marginRight: theme.spacing(1),
  },
  thinColumn: {
    marginRight: theme.spacing(2),
  },
  icon: {
    "&:focus": {
      outline: "none",
    },
  },
}));

export default injectIntl(connectComponent(ProfileDataPanel));
