import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import * as UiActions from "../../actions/Actions";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import AuthWrapper from "../AuthWrapper";
import PasswordInput from "../../component/input/PasswordInput";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { passwordValidationSchema } from "./forgotPasswordValidationSchema";
import { HttpService } from "../../http-service/HttpService";
import CircularProgress from "@material-ui/core/CircularProgress";

const SetNewPassword = ({
  intl,
  actions,
  user,
  match: {
    params: { userId, code },
  },
}) => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [newPasswordInfo, setNewPasswordInfo] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current && user) {
      firstUpdate.current = false;
      actions.userLoggedOut();
    }
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const resetPasswordDTO = {
        password: values.password,
        resetCode: code,
        userId: userId,
      };
      HttpService.setNewPassword(resetPasswordDTO)
        .then((userDTO) => {
          return HttpService.loginWithEmail({
            email: userDTO.email,
            password: values.password,
          });
        })
        .then((userDTO) => {
          history.push("/");
          actions.userLoggedIn(userDTO);
        })
        .catch((error) => {
          if (error.message === "classifier") {
            actions.userLoggedOut();
            setNewPasswordInfo(true);
          } else {
            setNewPasswordError(true);
            actions.userLoggedOut();
          }
        })
        .finally(() => {
          resetForm();
          setLoading(false);
        });
    },
    validationSchema: passwordValidationSchema(intl, password),
    isInitialValid: false,
  });

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    formik.handleChange(event);
  };

  return (
    <AuthWrapper type="login">
      <Typography variant="h4" className={classes.heading}>
        {translateValue(intl, "forgotPassword.resetPassword")}
      </Typography>
      {loading && <CircularProgress />}
      {newPasswordError && (
        <Alert className={classes.alert} severity="error">
          {translateValue(intl, "forgotPassword.reset.error")}
        </Alert>
      )}
      {newPasswordInfo && (
        <Alert className={classes.alert} severity="info">
          {translateValue(intl, "forgotPassword.reset.info")}
        </Alert>
      )}
      <PasswordInput
        value={formik.values.password}
        onChange={handlePasswordChange}
        onBlur={formik.handleBlur}
        error={formik.errors.password && formik.touched.password}
        helperText={
          formik.errors.password && formik.touched.password ? formik.errors.password : null
        }
      />
      <PasswordInput
        name="passwordConfirm"
        label={translateValue(intl, "forgotPassword.repeatPassword")}
        value={formik.values.passwordConfirm}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.passwordConfirm && formik.touched.passwordConfirm}
        helperText={
          formik.errors.passwordConfirm && formik.touched.passwordConfirm
            ? formik.errors.passwordConfirm
            : null
        }
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={formik.handleSubmit}
        disabled={loading}
      >
        {translateValue(intl, "forgotPassword.resetPassword")}
      </Button>
      <Link to="/" className={classes.backToLogin}>
        {translateValue(intl, "backToLogin")}
      </Link>
    </AuthWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: theme.spacing(4, 0, 4, 0),
  },
  alert: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  backToLogin: {
    marginBottom: theme.spacing(6),
    fontSize: "1rem",
  },
}));

function mapState(state) {
  return {
    user: state.user,
  };
}

function mapActions(dispatch) {
  return {
    actions: bindActionCreators(UiActions, dispatch),
  };
}

export default injectIntl(connect(mapState, mapActions)(SetNewPassword));
