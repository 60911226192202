import React from "react";
import { makeStyles } from "@material-ui/core/";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

const CheckboxWithLabel = ({ value, name, onChange, label }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={onChange}
          name={name}
          size="small"
          className={classes.checkbox}
        />
      }
      label={<Typography variant="body1">{label}</Typography>}
      className={classes.checkboxWithLabel}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  checkboxWithLabel: {
    margin: 0,
  },
  checkbox: {
    padding: theme.spacing(0.5, 1, 0.5, 1),

    [theme.breakpoints.up("xl")]: {
      padding: theme.spacing(1, 1, 1, 1),
    },
  },
}));

export default CheckboxWithLabel;
