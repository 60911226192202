import moment from "moment-timezone";

import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_UPDATED_ACCOUNT,
  CHANGE_LOCALE,
  SHOW_EDIT_FIRMWARE_MODAL,
  CLOSE_EDIT_FIRMWARE_MODAL,
  SHOW_DELETE_FILE_MODAL,
  CLOSE_DELETE_FILE_MODAL,
  SESSION_EXPIRATION_DATE,
  SHOW_EDIT_SENSOR_MODAL,
  CLOSE_EDIT_SENSOR_MODAL,
  ACCOUNT_ACTIVATION_FAILED,
} from "../actions/Actions";

const defaultState = {
  user: null,
  shouldCreateProfile: false,
  sensorToEdit: null,
  timezone: moment.tz.guess(),
  locale: getLocale(),
  expirationDate: null,
  activationFailed: false,
};

function getLocale() {
  const lang = (navigator.languages && navigator.languages[0]) || navigator.language;
  if (lang.startsWith("pl")) return "pl-PL";
  else return "en-US";
}

export function appReducer(state = defaultState, action) {
  switch (action.type) {
    case USER_LOGGED_IN: {
      return {
        ...state,
        user: action.user,
        shouldCreateProfile: action.shouldCreateProfile || false,
      };
    }
    case USER_LOGGED_OUT: {
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
        shouldCreateProfile: false,
        expirationDate: null,
        activationFailed: false,
      };
    }
    case USER_UPDATED_ACCOUNT: {
      return {
        ...state,
        user: action.user,
      };
    }
    case ACCOUNT_ACTIVATION_FAILED: {
      return {
        ...state,
        activationFailed: true,
      };
    }
    case SHOW_EDIT_FIRMWARE_MODAL: {
      return {
        ...state,
        firmwareToEdit: action.firmwareToEdit,
      };
    }
    case CLOSE_EDIT_FIRMWARE_MODAL: {
      return {
        ...state,
        firmwareToEdit: null,
      };
    }
    case CHANGE_LOCALE: {
      return {
        ...state,
        locale: action.locale,
      };
    }
    case SHOW_DELETE_FILE_MODAL: {
      return {
        ...state,
        fileTypeToDelete: action.fileTypeToDelete,
        fileId: action.fileId,
      };
    }
    case CLOSE_DELETE_FILE_MODAL: {
      return {
        ...state,
        fileTypeToDelete: null,
      };
    }
    case SESSION_EXPIRATION_DATE: {
      return {
        ...state,
        expirationDate: action.expirationDate,
      };
    }
    case SHOW_EDIT_SENSOR_MODAL: {
      return {
        ...state,
        sensorToEdit: action.sensorToEdit,
      };
    }
    case CLOSE_EDIT_SENSOR_MODAL: {
      return {
        ...state,
        sensorToEdit: null,
      };
    }
    default:
      return state;
  }
}
