import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { connectComponent } from "../../actions/ActionUtils";
import { HttpService } from "../../http-service/HttpService";
import { translateValue } from "../../i18n/ClebreTranslator";
import { useFormik } from "formik";
import ProfileForm from "../ProfileForm";
import DialogBase from "../../component/Dialog/DialogBase";
import { profileValidationSchema } from "../profileValidationSchema";
import { processFormValues } from "../../util/helpers";

const AddProfileDialog = (props) => {
  const { open, handleClose, intl, user, fetchProfiles, setSubmissionStatus } = props;

  const [govIdValidationCache] = useState({});
  const validationSchema = profileValidationSchema(user, intl, govIdValidationCache);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      govIdentification: "",
      dateOfBirth: "",
      gender: "",
      height: "",
      weight: "",
      phoneNumber: "",
    },
    onSubmit: (values, { resetForm }) => {
      setSubmissionStatus("");
      values["accountId"] = user.id;
      const normalizedValues = processFormValues(values);

      HttpService.addNewProfile(normalizedValues)
        .then(() => {
          resetForm();
          handleClose();
          fetchProfiles();
          setSubmissionStatus("success");
        })
        .catch((error) => {
          resetForm();
          handleClose();
          fetchProfiles();
          setSubmissionStatus("error");
        });
    },
    validationSchema: validationSchema,
    validateOnMount: true,
  });

  return (
    <DialogBase
      maxWidth="sm"
      fullWidth={true}
      open={open}
      title={translateValue(intl, "profile.add")}
      submitText={translateValue(intl, "button.add")}
      handleSubmit={formik.handleSubmit}
      handleReset={formik.resetForm}
      isValid={formik.isValid}
      handleClose={handleClose}
      {...props}
    >
      <ProfileForm govIdValidationCache={govIdValidationCache} formik={formik} type="add" />
    </DialogBase>
  );
};

export default connectComponent(injectIntl(AddProfileDialog));
