import _ from "lodash";

export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_UPDATED_ACCOUNT = "USER_UPDATED_ACCOUNT";
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const SHOW_EDIT_FIRMWARE_MODAL = "SHOW_EDIT_FIRMWARE_MODAL";
export const CLOSE_EDIT_FIRMWARE_MODAL = "CLOSE_EDIT_FIRMWARE_MODAL";
export const SHOW_DELETE_FILE_MODAL = "SHOW_DELETE_FILE_MODAL";
export const CLOSE_DELETE_FILE_MODAL = "CLOSE_DELETE_FILE_MODAL";
export const SESSION_EXPIRATION_DATE = "SESSION_EXPIRATION_DATE";
export const SHOW_EDIT_SENSOR_MODAL = "SHOW_EDIT_SENSOR_MODAL";
export const CLOSE_EDIT_SENSOR_MODAL = "CLOSE_EDIT_SENSOR_MODAL";
export const ACCOUNT_ACTIVATION_FAILED = "ACCOUNT_ACTIVATION_FAILED";

export function userLoggedIn(user) {
  return {
    type: USER_LOGGED_IN,
    user: user,
    shouldCreateProfile: _.isEmpty(user.profile),
  };
}

export function userLoggedOut() {
  localStorage.removeItem("menuActiveTab");
  return {
    type: USER_LOGGED_OUT,
  };
}

export function userUpdatedAccount(user) {
  return {
    type: USER_UPDATED_ACCOUNT,
    user: user,
  };
}

export function accountActivationFailed() {
  return {
    type: ACCOUNT_ACTIVATION_FAILED,
  };
}

export function showEditFirmwareModal(firmwareToEdit) {
  return {
    type: SHOW_EDIT_FIRMWARE_MODAL,
    firmwareToEdit: firmwareToEdit,
  };
}

export function closeEditFirmwareModal() {
  return {
    type: CLOSE_EDIT_FIRMWARE_MODAL,
  };
}

export function changeLocale(locale) {
  return {
    type: CHANGE_LOCALE,
    locale: locale,
  };
}

export function showDeleteFileModal(fileTypeToDelete, fileId) {
  return {
    type: SHOW_DELETE_FILE_MODAL,
    fileTypeToDelete: fileTypeToDelete,
    fileId: fileId,
  };
}

export function closeDeleteFileModal() {
  return {
    type: CLOSE_DELETE_FILE_MODAL,
  };
}

export function setExpirationDate(expirationDate) {
  return {
    type: SESSION_EXPIRATION_DATE,
    expirationDate: expirationDate,
  };
}

export function showEditSensorModal(sensorToEdit) {
  return {
    type: SHOW_EDIT_SENSOR_MODAL,
    sensorToEdit: sensorToEdit,
  };
}

export function closeEditSensorModal() {
  return {
    type: CLOSE_EDIT_SENSOR_MODAL,
  };
}
