import React, { useState } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { emailValidationSchema } from "./forgotPasswordValidationSchema";
import { HttpService } from "../../http-service/HttpService";
import CircularProgress from "@material-ui/core/CircularProgress";

const ResetView = ({ intl, setEmailSent }) => {
  const classes = useStyles();
  const [resetError, setResetError] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      HttpService.resetPassword(values.email)
        .then(() => {
          resetForm();
          setEmailSent(true);
          setLoading(false);
        })
        .catch(() => {
          setResetError(true);
          setLoading(false);
        });
    },
    validationSchema: emailValidationSchema(intl),
    isInitialValid: false,
  });

  const handleChangeAndTrim = (event) => {
    event.target.value = event.target.value.trim();
    formik.handleChange(event);
  };

  return (
    <>
      <Typography variant="h4" className={classes.heading}>
        {translateValue(intl, "forgotPassword.resetPassword")}
      </Typography>
      {resetError && (
        <Alert className={classes.alert} severity="error">
          {translateValue(intl, "forgotPassword.reset.error")}
        </Alert>
      )}
      {loading && <CircularProgress />}
      <TextField
        className={classes.emailInput}
        variant="outlined"
        name="email"
        fullWidth
        label={translateValue(intl, "email")}
        value={formik.values.email}
        onChange={handleChangeAndTrim}
        onBlur={formik.handleBlur}
        error={formik.errors.email && formik.touched.email}
        helperText={formik.errors.email && formik.touched.email ? formik.errors.email : null}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={formik.handleSubmit}
        disabled={loading}
      >
        {translateValue(intl, "forgotPassword.resetPassword")}
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(6),
  },
  heading: {
    marginTop: theme.spacing(4),
  },
  emailInput: {
    margin: theme.spacing(4, 0, 4, 0),
  },
  alert: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
}));

export default injectIntl(ResetView);
