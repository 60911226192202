import React, { Component } from "react";
import Box from "@material-ui/core/Box";

class TabPanel extends Component {
  render() {
    return (
      <div
        role="tabpanel"
        hidden={this.props.value !== this.props.index}
        id={`scrollable-auto-tabpanel-${this.props.index}`}
        aria-labelledby={`scrollable-auto-tab-${this.props.index}`}
      >
        {this.props.value === this.props.index ? <Box p={3}>{this.props.children}</Box> : null}
      </div>
    );
  }
}

export default TabPanel;
