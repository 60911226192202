import jwt_decode from "jwt-decode";
import { timestampToDate, getDuration } from "./Formatter";

const isTokenValid = (tokenExpiration) => {
  const currentDate = new Date();
  const expirationDate = new Date(tokenExpiration * 1000);
  return Math.round(getDuration(currentDate, expirationDate)) > 0;
};

export const decodeTokenAndLogin = (token, actions) => {
  let decodedToken = jwt_decode(token);
  if (isTokenValid(decodedToken.exp)) {
    let user = JSON.parse(decodedToken.body);
    actions.userLoggedIn(user);
    actions.setExpirationDate(timestampToDate(decodedToken.exp));
  } else {
    actions.userLoggedOut();
  }
};
