import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { connectComponent } from "../../actions/ActionUtils";
import { HttpService } from "../../http-service/HttpService";
import { translateValue } from "../../i18n/ClebreTranslator";
import { useFormik } from "formik";
import ProfileForm from "../ProfileForm";
import DialogBase from "../../component/Dialog/DialogBase";
import { profileValidationSchema } from "../profileValidationSchema";
import WeightOrHeightInput from "./WeightOrHeightInput";
import { processFormValues } from "../../util/helpers";

const EditProfileDialog = (props) => {
  const {
    type,
    open,
    handleClose,
    intl,
    user,
    profiledata,
    setProfileData,
    setProfile,
    setSubmissionStatus,
  } = props;
  const [govIdValidationCache] = useState({});

  govIdValidationCache[profiledata.govIdentification] = true;
  const validationSchema = profileValidationSchema(user, intl, govIdValidationCache);

  const formik = useFormik({
    initialValues: {
      firstName: profiledata.firstName || "",
      lastName: profiledata.lastName || "",
      govIdentification: profiledata.govIdentification || "",
      dateOfBirth: profiledata.dateOfBirth || "",
      gender: profiledata.gender || "",
      height: profiledata.height || "",
      weight: profiledata.weight || "",
      phoneNumber: profiledata.phoneNumber || "",
    },
    onSubmit: (values, { resetForm }) => {
      setSubmissionStatus("");
      const normalizedValues = processFormValues(values);

      HttpService.editProfile(normalizedValues, profiledata.profileId)
        .then((data) => {
          if (type === "general") {
            setProfile(data);
          }
          setProfileData(data);
          resetForm();
          handleClose();
          setSubmissionStatus("success");
        })
        .catch((error) => {
          resetForm();
          handleClose();
          setSubmissionStatus("error");
        });
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const generateTitle = (dialogType) => {
    let title;
    switch (dialogType) {
      case "general":
        title = translateValue(intl, "profile.edit");
        break;
      case "weight":
        title = translateValue(intl, "profile.edit.weight");
        break;
      case "height":
        title = translateValue(intl, "profile.edit.height");
        break;
      default:
        title = translateValue(intl, "profile.edit");
    }
    return title;
  };

  return (
    <DialogBase
      maxWidth={type === "general" ? "sm" : "xs"}
      fullWidth={type === "general"}
      open={open}
      title={generateTitle(type)}
      submitText={translateValue(intl, "button.save")}
      handleSubmit={formik.handleSubmit}
      handleReset={formik.resetForm}
      isValid={formik.isValid}
      handleClose={handleClose}
      {...props}
    >
      {type === "general" && (
        <ProfileForm govIdValidationCache={govIdValidationCache} formik={formik} type="edit" />
      )}
      {type === "weight" && <WeightOrHeightInput type={type} formik={formik} />}
      {type === "height" && <WeightOrHeightInput type={type} formik={formik} />}
    </DialogBase>
  );
};

export default connectComponent(injectIntl(EditProfileDialog));
