export const googleAuthErrors = {
  googlePopupClosed: {
    status: "popup_closed_by_user",
    message: "googlePopupClosed",
  },
  initializationFailed: {
    status: "idpiframe_initialization_failed",
    message: "",
  },
  genericError: { status: "genericError", message: "generic" },
};
