import React, { useEffect, useState } from "react";
import { HashRouter, Route } from "react-router-dom";
import _ from "lodash";
import { bindActionCreators } from "redux";
import * as UiActions from "./actions/Actions";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import CustomIntlProvider from "./component/IntlProvider/IntlProvider";
import SessionTimer from "./auth/login/SessionTimer";
import Home from "./home/Home";
import Examinations from "./examinations/Examinations";
import ExaminationDetails from "./examinations/ExaminationDetails";
import Firmware from "./firmware/Firmware";
import ProfilesPage from "./profiles/profilesView/ProfilesPage";
import ProfilePage from "./profiles/singleProfileView/ProfilePage";
import Users from "./users/Users";
import Sensors from "./sensors/Sensors";
import UserProfile from "./manage-account/UserProfile";
import LoginPage from "./auth/login/LoginPage";
import RegisterWrapper from "./auth/register/RegisterWrapper";
import AccountActivation from "./auth/register/AccountActivation";
import ForgotPasswordPage from "./auth/forgotPassword/ForgotPasswordPage";
import SetNewPassword from "./auth/forgotPassword/SetNewPassword";
import { AppWrapper } from "./main-page/AppWrapper";
import Classification from "./classification/Classification";
import { CircularOnBackdrop } from "./component/Progress/CircularOnBackdrop";
import { decodeTokenAndLogin } from "./util/decodeTokenAndLogin";

const App = ({ actions, user, shouldCreateProfile }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchIfUserAlreadyLoggedIn = () => {
      if (localStorage.token) {
        decodeTokenAndLogin(localStorage.token, actions);
      }
      setLoading(false);
    };

    fetchIfUserAlreadyLoggedIn();
  }, [actions]);

  let userIsLoggedIn = !_.isEmpty(user);

  if (loading) {
    return <CircularOnBackdrop />;
  }

  // Special case where user needs to create profile first before given access to full app.
  if (userIsLoggedIn && shouldCreateProfile) {
    return (
      <CustomIntlProvider>
        <HashRouter>
          <Route exact path="/" component={RegisterWrapper} />
          <Route path="/register" component={RegisterWrapper} />
          <Route path="/activate-account/:userId/:code" component={AccountActivation} />
          <Route path="/forgot-password/:userId/:code" component={SetNewPassword} />
          <SessionTimer />
        </HashRouter>
      </CustomIntlProvider>
    );
  }

  // Regular app access
  if (userIsLoggedIn) {
    return (
      <CustomIntlProvider>
        <AppWrapper>
          <HashRouter>
            <Route exact path="/" component={Home} />
            <Route path="/activate-account/:userId/:code" component={AccountActivation} />
            <Route path="/forgot-password/:userId/:code" component={SetNewPassword} />
            <Route path="/examinations" component={Examinations} />
            <Route path="/examinationDetails/:examinationId" component={ExaminationDetails} />
            <Route exact path="/profiles" component={ProfilesPage} />
            <Route path="/profiles/:profileId" component={ProfilePage} />
            <Route path="/classification" component={Classification} />
            <Route path="/users" component={Users} />
            <Route path="/sensors" component={Sensors} />
            <Route path="/firmware" component={Firmware} />
            <Route path="/user-profile" component={UserProfile} />
            <SessionTimer />
          </HashRouter>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover
          />
        </AppWrapper>
      </CustomIntlProvider>
    );
  }

  // Catch all for user not logged in.
  return (
    <CustomIntlProvider>
      <HashRouter>
        <Route exact path="/" component={LoginPage} />
        <Route path="/register" component={RegisterWrapper} />
        <Route path="/activate-account/:userId/:code" component={AccountActivation} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/forgot-password/:userId/:code" component={SetNewPassword} />
      </HashRouter>
    </CustomIntlProvider>
  );
};

function mapState(state) {
  return {
    user: state.user,
    shouldCreateProfile: state.shouldCreateProfile,
  };
}

function mapActions(dispatch) {
  return {
    actions: bindActionCreators(UiActions, dispatch),
  };
}

export default connect(mapState, mapActions)(App);
