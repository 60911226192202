import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";

class ClebreDetailsColumn extends Component {
  render() {
    return (
      <Grid item xs>
        <Box className={this.props.className ? this.props.className : null}>
          {this.props.stats.map((stat, index) => {
            if (index === 0) {
              return (
                <span className={"examinationResultsBigFont"} key={index}>
                  {stat}
                </span>
              );
            }
            return <div key={index}>{stat}</div>;
          })}
          <div className={"examinationResultsCaption"}>
            {this.props.captions.map((caption, index) =>
              caption ? <div key={index}>{translateValue(this.props.intl, caption)}</div> : null
            )}
          </div>
        </Box>
      </Grid>
    );
  }
}

export default injectIntl(ClebreDetailsColumn);
