export const checkRejectedFiles = (processedFiles, validationState) => {
  // All remaining files that are not added to required files in validation state:
  // - wrong extension
  // - wrong name (compared to jsn)
  // - extension duplicates (when there is no jsn)

  processedFiles.forEach((file) => {
    if (
      validationState.requiredFiles[file.extension].file &&
      validationState.requiredFiles[file.extension].file.name === file.file.name
    ) {
      // This file was added to required files; ommit it
      return;
    }

    // If these is JSN in validation state, file was dropped because of wrong name
    if (validationState.requiredFiles["JSN"].file) {
      validationState.droppedFiles.wrongName.push(file);
      return;
    }
    // If there is not JSN, file was dropped because it is duplicate
    // duplicate in terms of extension
    validationState.droppedFiles.duplicates.push(file);
  });
};
