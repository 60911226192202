import React from "react";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";

const AddButton = ({ children, onClick }) => {
  return (
    <Button variant="contained" color="primary" size="large" startIcon={<Add />} onClick={onClick}>
      {children}
    </Button>
  );
};

export default AddButton;
