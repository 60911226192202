import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { translateValue } from "../../i18n/ClebreTranslator";

class ClebreFormModal extends Component {
  render() {
    const onHide = () => (this.props.onHide ? this.props.onHide() : this.props.history.goBack());
    const saveLabel = this.props.saveLabel ? this.props.saveLabel : "button.save";
    const closeLabel = this.props.closeLabel ? this.props.closeLabel : "button.close";
    return (
      <Dialog open={this.props.show} onClose={() => onHide()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {translateValue(this.props.intl, this.props.i18nLabelTitle)}
        </DialogTitle>
        <DialogContent>{this.props.formBody}</DialogContent>
        <DialogActions>
          <Button onClick={() => onHide()} color="primary">
            {translateValue(this.props.intl, closeLabel)}
          </Button>
          <Button onClick={(event) => this.props.onSave(event)} color="primary">
            {translateValue(this.props.intl, saveLabel)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(withRouter(ClebreFormModal));
