import React from "react";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { translateValue } from "../../../i18n/ClebreTranslator";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

const AdditionalInformation = ({ validationState, handleReset, intl }) => {
  const wrongExtension = !_.isEmpty(validationState.droppedFiles.wrongExtension);
  const wrongFileName = !_.isEmpty(validationState.droppedFiles.wrongName);
  const duplicatedFiles = !_.isEmpty(validationState.droppedFiles.duplicates);
  const tooBigFiles = !_.isEmpty(validationState.droppedFiles.tooBig);
  const rejectedFiles = wrongExtension | wrongFileName | duplicatedFiles | tooBigFiles;

  const classes = useStyles();

  return (
    <Container className={classes.additionalInfo}>
      <div className={classes.footer}>
        <Typography>
          <ReportProblemOutlinedIcon className={classes.warning} />
          {translateValue(intl, "file.wav.add")}
        </Typography>
        <Button onClick={handleReset} variant="outlined" size="small">
          {translateValue(intl, "resetForm")}
        </Button>
      </div>
      {rejectedFiles ? (
        <Typography variant="h6" className={classes.rejectedFiles}>
          {translateValue(intl, "examination.add.rejectedFiles")}
        </Typography>
      ) : null}
      {wrongFileName && (
        <div className={classes.rejectedFilesList}>
          <Typography className={classes.listHeader} variant="body2">
            {translateValue(intl, "examination.add.wrongFilename")}
          </Typography>

          {validationState.droppedFiles.wrongName.map((file) => {
            return (
              <Typography
                key={`${file.name}.${file.extension}`}
                variant="body2"
                className={classes.rejectedFile}
              >
                {file.name}.{file.extension}
              </Typography>
            );
          })}
        </div>
      )}
      {wrongExtension && (
        <div className={classes.rejectedFilesList}>
          <Typography className={classes.listHeader} variant="body2">
            {translateValue(intl, "examination.add.wrongExtension")}
          </Typography>

          {validationState.droppedFiles.wrongExtension.map((file) => {
            return (
              <Typography
                key={`${file.name}.${file.extension}`}
                variant="body2"
                className={classes.rejectedFile}
              >
                {file.name}.{file.extension}
              </Typography>
            );
          })}
        </div>
      )}
      {duplicatedFiles && (
        <div className={classes.rejectedFilesList}>
          <Typography className={classes.listHeader} variant="body2">
            {translateValue(intl, "examination.add.duplicates")}
          </Typography>

          {validationState.droppedFiles.duplicates.map((file) => {
            return (
              <Typography
                key={`${file.name}.${file.extension}`}
                variant="body2"
                className={classes.rejectedFile}
              >
                {file.name}.{file.extension}
              </Typography>
            );
          })}
        </div>
      )}
      {tooBigFiles && (
        <div className={classes.rejectedFilesList}>
          <Typography className={classes.listHeader} variant="body2">
            {translateValue(intl, "examination.add.tooBig")}
          </Typography>

          {validationState.droppedFiles.tooBig.map((file) => {
            return (
              <Typography
                key={`${file.name}.${file.extension}`}
                variant="body2"
                className={classes.rejectedFile}
              >
                {file.name}.{file.extension}
              </Typography>
            );
          })}
        </div>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.dark,
  },
  additionalInfo: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2, 0, 2),
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  rejectedFiles: {
    margin: theme.spacing(1, 0, 1, 0),
    fontWeight: "bold",
  },
  rejectedFilesList: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1, 0, 1, 0),
  },
  rejectedFile: {
    marginRight: theme.spacing(1),
  },
  listHeader: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
}));

export default injectIntl(AdditionalInformation);
