import React from "react";
import { translateValue } from "../../i18n/ClebreTranslator";
import { injectIntl } from "react-intl";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const NoResultsRow = ({ intl, span }) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={span}>
        {translateValue(intl, "noDataFound")}
      </TableCell>
    </TableRow>
  );
};

export default injectIntl(NoResultsRow);
