import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import PaperBase from "../Paper/PaperBase";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { connectComponent } from "../../actions/ActionUtils";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";

class SearchBox extends Component {
  render() {
    return (
      <PaperBase>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <IconButton
              className={this.props.classes.searchIcon}
              color="primary"
              aria-label="search profiles"
              onClick={() => this.props.fetchData()}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth={true}
              className={this.props.classes.searchBox}
              id="input-with-icon-textfield"
              value={this.props.searchPhrase}
              placeholder={translateValue(this.props.intl, "search.placeholder")}
              onChange={(event) => this.props.onSearchChange(event)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  this.props.fetchData();
                }
              }}
            />
          </Grid>
        </Grid>
      </PaperBase>
    );
  }
}

const styles = (theme) => ({
  searchBox: {
    margin: theme.spacing(1),
  },
  searchIcon: {
    float: "right",
  },
});

export default withStyles(styles)(connectComponent(injectIntl(SearchBox)));
