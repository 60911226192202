import React from "react";
import { injectIntl } from "react-intl";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { translateValue } from "../../i18n/ClebreTranslator";
import StartClassificationButton from "./StartClassificationButton";
import StopClassificationButton from "./StopClassificationButton";
import { convertTimezone } from "../../util/timezoneConverter";
import { connectComponent } from "../../actions/ActionUtils";
import DateTimeValue from "../../component/date-time-value/DateTimeValue";

const DataRow = (props) => {
  const { classification } = props;

  const startPossible = ["POSSIBLE_TO_START", "ERROR", "FINISHED"];

  return (
    <TableRow key={`${classification.classificationRoundId || classification.examinationId}`}>
      <TableCell> {classification.classificationRoundNaturalId || "-"} </TableCell>
      <TableCell> {classification.examinationNaturalId} </TableCell>
      <TableCell>
        {classification.examinationDateTime && (
          <DateTimeValue
            datetime={convertTimezone(classification.examinationDateTime, props.timezone)}
            formatDate={"YYYY-MM-DD"}
          />
        )}
      </TableCell>
      <TableCell>
        {classification.startDateTime && (
          <DateTimeValue
            datetime={convertTimezone(classification.startDateTime, props.timezone)}
            formatDate={"YYYY-MM-DD"}
          />
        )}
      </TableCell>
      <TableCell>
        {classification.endDateTime && (
          <DateTimeValue
            datetime={convertTimezone(classification.endDateTime, props.timezone)}
            formatDate={"YYYY-MM-DD"}
          />
        )}
      </TableCell>
      <TableCell>
        {" "}
        {translateValue(props.intl, `classification.status.${classification.status}`)}
      </TableCell>
      <TableCell> {classification.classificationEpisodesCount} </TableCell>
      <TableCell> {100 * classification.percentOfEpisodesClassified} % </TableCell>
      <TableCell> {100 * classification.percentOfEpisodesLabelMatchedWithAlgorithm} % </TableCell>
      <TableCell>
        {startPossible.includes(classification.status) && (
          <>
            <StartClassificationButton
              examinationId={classification.examinationId}
              setLoading={props.setLoading}
              memoizedFetch={props.memoizedFetch}
              actionStatus={props.actionStatus}
              version="old"
            />
            <StartClassificationButton
              examinationId={classification.examinationId}
              setLoading={props.setLoading}
              memoizedFetch={props.memoizedFetch}
              actionStatus={props.actionStatus}
              version="new"
            />
          </>
        )}
        {classification.status === "IN_PROGRESS" && (
          <StopClassificationButton
            classificationRoundId={classification.classificationRoundId}
            setLoading={props.setLoading}
            memoizedFetch={props.memoizedFetch}
            actionStatus={props.actionStatus}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default injectIntl(connectComponent(DataRow));
