import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { injectIntl } from "react-intl";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core";
import { translateValue } from "../../i18n/ClebreTranslator";

class ClebreDetailsImage extends Component {
  render() {
    const firstLabel = this.props.firstLabel ? this.props.firstLabel : "";
    const secondLabel = this.props.secondLabel ? this.props.secondLabel : "";
    return (
      <Grid item xs>
        <Avatar alt=" " src={this.props.image} className={this.props.classes.avatarLarge} />
        <label>{translateValue(this.props.intl, firstLabel)}</label>
        <Grid>
          <label>{secondLabel}</label>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  avatarLarge: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    display: "block",
    margin: "0 auto",
  },
});

export default withStyles(styles)(injectIntl(ClebreDetailsImage));
