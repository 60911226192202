import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { injectIntl } from "react-intl";
import { HttpService } from "../../http-service/HttpService";
import { connectComponent } from "../../actions/ActionUtils";
import { translateValue } from "../../i18n/ClebreTranslator";
import {
  findPatientExaminations,
  findExaminationDuration,
  capitalize,
  getTodayDate,
  getYesterdayDate,
  renderWithNarrowedDecimalPoints,
} from "../../util/helpers";
import { formatDurationInSeconds } from "../../util/Formatter";
import { convertTimezone } from "../../util/timezoneConverter";
import ClebreTableHeader from "../../component/ClebreTable/ClebreTableHeader";
import DateTimeValue from "../../component/date-time-value/DateTimeValue";
import PaperBase from "../../component/Paper/PaperBase";
import AddButton from "../../component/Button/AddButton";
import ClebreTablePagination from "../../component/ClebreTable/ClebreTablePagination";
import { generateColumnField } from "../../component/ClebreTable/generateTableColumn";
import NoResultsRow from "../../component/ClebreTable/NoResultsRow";
import ProfileDataPanel from "./ProfileDataPanel";
import ExaminationUpload from "./examinationUpload/ExaminationUpload";
import Toast from "../../component/Toast/Toast";
import { CircularOnBackdrop } from "../../component/Progress/CircularOnBackdrop";
import { examinationStatuses } from "./examinationUpload/constants";

const ProfilePage = ({ intl, ...props }) => {
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("lastName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [profile, setProfile] = useState({ sensorLeases: [] });
  const [patientExaminations, setPatientExaminations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadResult, setUploadResult] = useState("");
  const history = useHistory();

  const classes = useStyles();
  const profileIdParam = props.match.params.profileId;
  const examinationStatusInterval = useRef(null);

  const fetchProfile = (profileId, showLoader = true) => {
    if (showLoader) {
      setLoading(true);
    }
    HttpService.getProfileById(profileId)
      .then((response) => {
        setProfile(response);
        const patientExaminationsList = findPatientExaminations(response.examinations);
        setPatientExaminations(patientExaminationsList);
        setLoading(false);
        return patientExaminationsList;
      })
      .then((patientExaminationsList) => {
        if (_.isEmpty.patientExaminationsList) return;

        const examinationsInProgress = [];
        for (const examination of patientExaminationsList) {
          if (!examination.examination.examinationStatus) continue;
          const examinationStatus = examination.examination.examinationStatus.examinationStatus;
          if (
            examinationStatus === examinationStatuses.PENDING ||
            examinationStatus === examinationStatuses.IN_PROGRESS
          ) {
            examinationsInProgress.push(examination.examination.examinationId);
          }
        }
        if (examinationsInProgress.length > 0) {
          examinationStatusPolling(examinationsInProgress);
        } else {
          clearInterval(examinationStatusInterval.current);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const examinationStatusPolling = (examinationsInProgress) => {
    clearInterval(examinationStatusInterval.current);
    examinationStatusInterval.current = setInterval(() => {
      checkExaminations(examinationsInProgress);
    }, 3000);
  };

  const checkExaminations = (examinationsInProgress) => {
    for (const examinationId of examinationsInProgress) {
      checkExaminationStatus(examinationId);
    }
  };

  const checkExaminationStatus = (examinationId) => {
    return HttpService.checkExaminationStatus(examinationId).then((statusList) => {
      const examinationStatus = _.isEmpty(statusList) ? noData : statusList.pop().examinationStatus;

      if (
        examinationStatus !== examinationStatuses.PENDING ||
        examinationStatus !== examinationStatuses.IN_PROGRESS
      ) {
        fetchProfile(profileIdParam, false);
      }
    });
  };

  useEffect(() => {
    fetchProfile(profileIdParam);
    return () => clearInterval(examinationStatusInterval.current);
  }, [profileIdParam]);

  const openUploadDialog = () => {
    setUploadDialogOpen(true);
  };

  const closeUploadDialog = () => {
    setUploadDialogOpen(false);
  };

  const handleRowClick = (event) => {
    const examinationId = event.currentTarget.id;
    history.push(`/examinationDetails/${examinationId}`);
  };

  const headCellValues = [
    { name: "addedAt", label: translateValue(intl, "examination.addedAt") },
    {
      name: "examinationStartDate",
      label: translateValue(intl, "examination.startDate"),
    },
    {
      name: "examinationDuration",
      label: translateValue(intl, "examination.duration"),
    },
    { name: "sleepTime", label: translateValue(intl, "examination.sleepTime") },
    {
      name: "sleepScore",
      label: translateValue(intl, "examination.sleepScore"),
    },
    { name: "ahi", label: translateValue(intl, "sleepScore.ahi/sahi") },
    {
      name: "snoringIndex",
      label: translateValue(intl, "sleepScore.snoringIndex/supineSnoreRate"),
    },
    {
      name: "examinationStatus",
      label: translateValue(intl, "examination.status"),
    },
  ];

  const headCells = headCellValues.map((column) => {
    return generateColumnField(
      column.name,
      column.label,
      true,
      sortOrder,
      sortField,
      setSortOrder,
      setSortField
    );
  });

  const lastName = profile.lastName ? capitalize(profile.lastName) : "";
  const firstName = profile.firstName ? capitalize(profile.firstName) : "";
  const noData = translateValue(intl, "noData");
  const totalRecords = patientExaminations.length;

  return (
    <div className={classes.pageContent}>
      <div className={classes.pageHeader}>
        <Typography variant="h4">{`${translateValue(
          intl,
          "profile.examinations"
        )} ${lastName} ${firstName}`}</Typography>
        <AddButton onClick={openUploadDialog}>
          {translateValue(intl, "button.addExamination")}
        </AddButton>
      </div>
      <ExaminationUpload
        open={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        profileId={profile.profileId}
        setUploadResult={setUploadResult}
        fetchProfile={fetchProfile}
      ></ExaminationUpload>
      <ProfileDataPanel profile={profile} setProfile={setProfile} />
      <PaperBase classes={{ root: classes.tableContainer }}>
        <Table>
          <ClebreTableHeader headCells={headCells} />
          <TableBody>
            {patientExaminations.map((examination) => {
              const {
                startDateTime,
                examinationStatus,
                examinationId,
                addedAt,
              } = examination.examination;
              const status = examinationStatus
                ? translateValue(intl, examinationStatus.examinationStatus)
                : noData;
              const { sleepScore } = examination.examination;
              const ahi = sleepScore ? sleepScore.ahi : noData;
              const supineAhi = sleepScore ? sleepScore.supineAhi : noData;
              const snoringIndex = sleepScore
                ? renderWithNarrowedDecimalPoints(sleepScore.snoringIndex * 100, 0)
                : noData;
              const supineSnoringIndex = sleepScore
                ? renderWithNarrowedDecimalPoints(sleepScore.supineSnoringIndex * 100, 0)
                : noData;
              const generalResult = sleepScore ? sleepScore.sleepScore : noData;
              const today = getTodayDate();
              const yesterday = getYesterdayDate();
              const formattedAddedAt = addedAt.split("T")[0];

              return (
                <TableRow
                  key={examinationId}
                  id={examinationId}
                  onClick={handleRowClick}
                  hover
                  className={classes.tableRowHover}
                >
                  <TableCell>
                    {formattedAddedAt === today && translateValue(intl, "today")}
                    {formattedAddedAt === yesterday && translateValue(intl, "yesterday")}
                    {formattedAddedAt !== today && formattedAddedAt !== yesterday && (
                      <DateTimeValue datetime={convertTimezone(addedAt, props.timezone)} />
                    )}
                  </TableCell>
                  <TableCell>
                    <DateTimeValue datetime={convertTimezone(startDateTime, props.timezone)} />
                  </TableCell>
                  <TableCell>{findExaminationDuration(examination.examination)}</TableCell>
                  <TableCell>
                    {sleepScore ? formatDurationInSeconds(sleepScore.sleepInSeconds) : noData}
                  </TableCell>
                  <TableCell>{generalResult}</TableCell>
                  <TableCell>{`${ahi}/${supineAhi}`}</TableCell>
                  <TableCell>{`${snoringIndex}/${supineSnoringIndex}`}</TableCell>
                  <TableCell>{status}</TableCell>
                </TableRow>
              );
            })}
            {!loading && totalRecords === 0 && <NoResultsRow span={8} />}
          </TableBody>
        </Table>
        <ClebreTablePagination
          page={page}
          limit={limit}
          totalRecords={totalRecords}
          setLimit={setLimit}
          setPage={setPage}
        />
      </PaperBase>
      {uploadResult === "error" && (
        <Toast
          severity="error"
          setUploadResult={setUploadResult}
          message={translateValue(intl, "examination.uploadError")}
        />
      )}
      {uploadResult === "success" && (
        <Toast
          severity="success"
          setUploadResult={setUploadResult}
          message={translateValue(intl, "examination.uploadSuccess")}
        />
      )}
      {loading && <CircularOnBackdrop />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: "0px",
  },
  pageHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageContent: {
    marginTop: theme.spacing(4),
  },
  tableRowHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default injectIntl(connectComponent(ProfilePage));
