export const selectJsn = (validationState, processedFiles) => {
  let JSNfound = false;

  processedFiles.forEach((file) => {
    if (file.extension === "JSN" && !JSNfound) {
      // Override JSN with the first found
      JSNfound = true;
      validationState.requiredFiles["JSN"].file = file.file;
      validationState.requiredFiles["JSN"].name = file.name;
      validationState.examinationId.id = file.name;
    } else if (file.extension === "JSN") {
      // Add other JSNs to duplicates
      validationState.droppedFiles.duplicates.push(file);
    }
  });

  // Remove all JSNs after validation
  processedFiles = processedFiles.filter((file) => file.extension !== "JSN");

  return Promise.resolve([validationState, processedFiles]);
};

export const validateJsn = ([validationState, processedFiles]) => {
  let selectedJsn = validationState.requiredFiles["JSN"].file;

  // If no JSN, resolve promise and proceed to the next function
  if (!selectedJsn) {
    return Promise.resolve([validationState, processedFiles]);
  }

  // If there is JSN, read it and validate structure
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (event) => {
      let jsnContent;
      try {
        jsnContent = JSON.parse(event.target.result);
        const isValid = checkStructure(jsnContent);
        if (isValid) {
          const examinationId = jsnContent.examination_id;
          validationState.requiredFiles["JSN"].valid = true;
          validationState.examinationId.id = examinationId;
          validationState.sensorId.id = jsnContent.sensor_serial;

          // If examination ID in the JSN is different than file name
          if (
            validationState.requiredFiles["JSN"].name.toUpperCase() !==
            examinationId.replace(/[^a-zA-Z0-9]/g, "_").toUpperCase()
          ) {
            validationState.requiredFiles["JSN"].valid = false;
            validationState.requiredFiles["JSN"].message = "examination.wrongID";
          }
        } else {
          validationState.requiredFiles["JSN"].valid = false;
          validationState.requiredFiles["JSN"].message = "examination.wrongJsnStructure";
        }
        resolve([validationState, processedFiles]);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsText(selectedJsn);
  });
};

const checkStructure = (jsn) => {
  const requiredKeys = [
    "sensor_serial",
    "examination_id",
    "examination_start_timestamp",
    "examination_stop_timestamp",
    "firmware_version",
    "hardware_revision",
    "classifier_1_version",
  ];

  const valid = requiredKeys.every((key) => jsn.hasOwnProperty(key));

  return valid;
};
