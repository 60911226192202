import React from "react";
import { connectComponent } from "../../actions/ActionUtils";
import { useGoogleLogin } from "react-google-login";
import { HttpService } from "../../http-service/HttpService";
import { makeStyles } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import { googleAuthErrors } from "./googleAuthErrors";
import { loginErrors } from "../../auth/login/loginErrors";

const GoogleAuthButton = ({ resetForm, setError, setLoading, actions, label, setInfo }) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
  const classes = useStyles();

  const onSuccess = (response) => {
    resetForm();
    setError(null);
    setInfo(null);
    setLoading(true);
    HttpService.loginWithGoogleCode(response.code)
      .then((user) => {
        actions.userLoggedIn(user);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message === loginErrors.classifier.status) {
          setInfo(loginErrors.classifier.message);
        } else {
          setError(googleAuthErrors.genericError.message);
        }
        actions.userLoggedOut();
        setLoading(false);
      });
  };

  const onFailure = (response) => {
    resetForm();
    setError(null);
    setInfo(null);
    if (response.error === googleAuthErrors.googlePopupClosed.status) {
      setError(googleAuthErrors.googlePopupClosed.message);
    } else if (response.error === googleAuthErrors.initializationFailed.status) {
      setError(null);
    } else {
      setError(googleAuthErrors.genericError.message);
    }
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    responseType: "code",
    scope: "openid email profile",
    redirectUri,
  });

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={signIn}
      startIcon={<Avatar className={classes.avatar} src="images/google-icon.svg" />}
    >
      {label}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
}));

export default connectComponent(GoogleAuthButton);
